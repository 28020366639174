import block1 from "../../../assets/2023/design-assets/studio_block_1.png";
import block2 from "../../../assets/2023/design-assets/studio_block_2.png";
import block3 from "../../../assets/2023/design-assets/studio_block_3.png";
import block4 from "../../../assets/2023/design-assets/studio_block_4.png";
import block5 from "../../../assets/2023/design-assets/studio_block_5.png";
import block6 from "../../../assets/2023/design-assets/studio_block_6.png";
import block7 from "../../../assets/2023/design-assets/studio_block_7.png";
import block8 from "../../../assets/2023/design-assets/studio_block_8.png";
import block9 from "../../../assets/2023/design-assets/studio_block_9.png";
import block10 from "../../../assets/2023/design-assets/studio_block_10.png";
import block11 from "../../../assets/2023/design-assets/studio_block_11.png";
import block12 from "../../../assets/2023/design-assets/studio_block_12.png";

import sebalsot_1 from "../../../assets/2023/design-assets/studio-images/sebalsot_1.png";
import sebalsot_2 from "../../../assets/2023/design-assets/studio-images/sebalsot_2.png";
import sebalsot_3 from "../../../assets/2023/design-assets/studio-images/sebalsot_3.png";
import sebalsot_4 from "../../../assets/2023/design-assets/studio-images/sebalsot_4.png";
import sebalsot_5 from "../../../assets/2023/design-assets/studio-images/sebalsot_5.png";

import bulldozer_1 from "../../../assets/2023/design-assets/studio-images/bulldozer_1.jpg";
import bulldozer_2 from "../../../assets/2023/design-assets/studio-images/bulldozer_2.jpg";
import bulldozer_3 from "../../../assets/2023/design-assets/studio-images/bulldozer_3.jpg";
import bulldozer_4 from "../../../assets/2023/design-assets/studio-images/bulldozer_4.jpg";

import dapalm_1 from "../../../assets/2023/design-assets/studio-images/dapalm_1.jpg";
import dapalm_2 from "../../../assets/2023/design-assets/studio-images/dapalm_2.jpg";
import dapalm_3 from "../../../assets/2023/design-assets/studio-images/dapalm_3.jpg";
import dapalm_4 from "../../../assets/2023/design-assets/studio-images/dapalm_4.jpg";
import dapalm_5 from "../../../assets/2023/design-assets/studio-images/dapalm_5.jpg";

import macadamiaoh from "../../../assets/2023/design-assets/studio-images/macadamiaoh_1.png";

import pmy_1 from "../../../assets/2023/design-assets/studio-images/pmy_resize_1.jpg";
import pmy_2 from "../../../assets/2023/design-assets/studio-images/pmy_resize_2.jpg";
import pmy_3 from "../../../assets/2023/design-assets/studio-images/pmy_resize_3.jpg";
import pmy_4 from "../../../assets/2023/design-assets/studio-images/pmy_resize_4.jpg";

import kimheekyung_1 from "../../../assets/2023/design-assets/studio-images/kimheekyung_resize_1.jpg";
import hongyejin_1 from "../../../assets/2023/design-assets/studio-images/hongyejin_resize_1.jpg";

import nest_1 from "../../../assets/2023/design-assets/studio-images/nest_1.jpg";
import nest_2 from "../../../assets/2023/design-assets/studio-images/nest_2.jpg";
import nest_3 from "../../../assets/2023/design-assets/studio-images/nest_3.jpg";
import nest_4 from "../../../assets/2023/design-assets/studio-images/nest_4.jpg";
import nest_5 from "../../../assets/2023/design-assets/studio-images/nest_5.jpg";

import seum_1 from "../../../assets/2023/design-assets/studio-images/seum_resize_1.jpg";
import seum_2 from "../../../assets/2023/design-assets/studio-images/seum_resize_2.jpg";
import seum_3 from "../../../assets/2023/design-assets/studio-images/seum_resize_3.jpg";
import seum_4 from "../../../assets/2023/design-assets/studio-images/seum_resize_4.jpg";
import seum_5 from "../../../assets/2023/design-assets/studio-images/seum_resize_5.jpg";

import works_1 from "../../../assets/2023/design-assets/studio-images/works_resize_1.jpg";
import works_2 from "../../../assets/2023/design-assets/studio-images/works_resize_2.jpg";
import works_3 from "../../../assets/2023/design-assets/studio-images/works_resize_3.jpg";

import jiwoo_1 from "../../../assets/2023/design-assets/studio-images/jiwoo_resize_1.jpg";
import jiwoo_2 from "../../../assets/2023/design-assets/studio-images/jiwoo_resize_2.jpg";
import somakim_1 from "../../../assets/2023/design-assets/studio-images/somakim_resize_1.jpg";
import somakim_2 from "../../../assets/2023/design-assets/studio-images/somakim_resize_2.jpg";
import heawon_1 from "../../../assets/2023/design-assets/studio-images/heawon_resize_1.jpg";
import heawon_2 from "../../../assets/2023/design-assets/studio-images/heawon_resize_2.jpg";
import jangdaeun_1 from "../../../assets/2023/design-assets/studio-images/jangdaeun_resize_1.jpg";
import jangdaeun_2 from "../../../assets/2023/design-assets/studio-images/jangdaeun_resize_2.jpg";
import jangdaeun_3 from "../../../assets/2023/design-assets/studio-images/jangdaeun_resize_3.jpg";

import brownbuilding_1 from "../../../assets/2023/design-assets/studio-images/brownbuilding_resize_1.jpg";
import brownbuilding_2 from "../../../assets/2023/design-assets/studio-images/brownbuilding_resize_2.jpg";
import brownbuilding_3 from "../../../assets/2023/design-assets/studio-images/brownbuilding_resize_3.jpg";
import brownbuilding_4 from "../../../assets/2023/design-assets/studio-images/brownbuilding_resize_4.jpg";
import brownbuilding_5 from "../../../assets/2023/design-assets/studio-images/brownbuilding_resize_5.jpg";

import listen1 from "../../../assets/2023/design-assets/studio-images/listen_1.jpg";
import listen2 from "../../../assets/2023/design-assets/studio-images/listen_2.jpg";
import listen3 from "../../../assets/2023/design-assets/studio-images/listen_3.jpg";
import listen4 from "../../../assets/2023/design-assets/studio-images/listen_resize_1.png";
import listen5 from "../../../assets/2023/design-assets/studio-images/listen_resize2.png";
import listen6 from "../../../assets/2023/design-assets/studio-images/listen_resize3.png";
import listen7 from "../../../assets/2023/design-assets/studio-images/listen_resize4.png";

enum SecondStudioName {
  Brownbuilding = "Brown building",
  listentotheCity = "Listen to the City(리슨투더시티)",
  WORKS = "WORKS",
  joySomaHangaQoo = "김지우, 소마킴, 이혜원, 장다은",
  seum = "공간 슴",
  nest = "둥지",
  paxhupyeahjiiin = "박민영, 김희경, 홍예진",
  macadamiaOh = "마카다미아 오",
  meanyounglamb = "불도저",
  sebalsot = "세발솥",
}

export enum SecondStudioId {
  OpenStudio = "open-studio",
  Brownbuilding = "brownbuilding.kr",
  listentotheCity = "listentothecity",
  WORKS = "works.works",
  joySomaHangaQoo = "joySomaHangaQoo",
  seum = "seum_2022",
  nest = "nest.cc",
  paxhupyeahjiiin = "paxhupyeahjiiin",
  macadamiaOh = "macadamiaOh",
  meanyounglamb = "meanyounglamb",
  sebalsot = "sebalsot",
  OpenStudioTime = "open-studio-time",
}

export const StudioBlock: Record<SecondStudioId, string> = {
  [SecondStudioId.OpenStudio]: block1,
  [SecondStudioId.Brownbuilding]: block2,
  [SecondStudioId.listentotheCity]: block3,
  [SecondStudioId.WORKS]: block4,
  [SecondStudioId.joySomaHangaQoo]: block5,
  [SecondStudioId.seum]: block6,
  [SecondStudioId.nest]: block7,
  [SecondStudioId.paxhupyeahjiiin]: block8,
  [SecondStudioId.macadamiaOh]: block9,
  [SecondStudioId.meanyounglamb]: block10,
  [SecondStudioId.sebalsot]: block11,
  [SecondStudioId.OpenStudioTime]: block12,
};

export type SecondStudioContent = {
  index: number;
  name: string;
  id: string;
  img: string[];
  imgCaption?: string[];
  member: string[];
  text: string[];
  address: string;
  schedules: string[];
  bookingLink?: string;
};

export const SECOND_STUDIOS_CONTENTS: SecondStudioContent[] = [
  {
    index: 0,
    id: "open-studio",
    name: "open-studio",
    schedules: [``],
    img: [``],
    member: [``],
    text: [``],
    address: ``,
  },
  {
    index: 1,
    id: SecondStudioId.Brownbuilding,
    name: SecondStudioName.Brownbuilding,
    schedules: [
      `15일(금) 13:00 - 18:00 (자유방문)`,
      `16일(토) 13:00 - 18:00 (자유방문)`,
      `17일(일) 13:00 - 18:00 (자유방문)`,
    ],
    img: [
      brownbuilding_1,
      brownbuilding_2,
      brownbuilding_3,
      brownbuilding_4,
      brownbuilding_5,
    ],
    member: [SecondStudioName.Brownbuilding],
    text: [
      `실용적인 소가구와 비실용적인 오브제 등을 제작하고 있습니다. 나무, 도자기, 금속 등 다양한 재료를 사용하고 있으며 스튜디오는 연희동에 위치합니다. 제가 만드는 것이 누군가의 마음에 위안이 되어주거나 또는 정말 요긴하게 쓰여서 만족감을 느끼기를 바라는 마음으로 제작활동을 이어가고 있습니다. www.brownbuilding.kr / @brownbuilding.kr`,
    ],
    address: "서울특별시 서대문구 연희로 52-6, 1층 브라운빌딩",
  },
  {
    index: 2,
    id: SecondStudioId.listentotheCity,
    name: SecondStudioName.listentotheCity,
    schedules: [`15일(금) 14:00 - 16:00 (예약 방문) (*오픈팩토리)`],
    bookingLink: `http://bit.ly/3RltxWk`,
    img: [listen4, listen5, listen6, listen7, listen1, listen2, listen3],
    member: [`Listen to the City (리슨투더시티)`],
    text: [
      `리슨투더시티 올해 <오픈스튜디오>는 <오픈팩토리>로 참여합니다! 리슨투더시티는 2015년부터 을지로에 둥지를 틀었습니다. 재개발로 점점 사라지는 골목과 우리 이웃들을 지키고 싶습니다. 공장은 놀라운 곳이예요! 함께 사장님들을 직접 만나보고 대안을 상상해 봅시다.

🏡부대행사
<오픈팩토리>
리슨투더시티 올해 <오픈스튜디오>는 <오픈팩토리>로 참여합니다! 리슨투더시티는 2015년부터 을지로에 둥지를 틀었습니다. 재개발로 점점 사라지는 골목과 우리 이웃들을 지키고 싶습니다. 공장은 놀라운 곳이예요! 함께 사장님들을 직접 만나보고 대안을 상상해 봅시다. 

* 모이는 시간 :  9월 15일 금요일 오후 2시 (약 2시간소요, 1시 50분까지 도착 부탁드립니다.) 
* 모이는 장소 : 을지로3가역 4번 출구 앞 
* 답사 안내자 : 은선(리슨투더시티, 청계천을지로보존연대)
* 모집인원 : 최대 15명(신청 선착순)
* 준비물 : 편한 옷차림과 마실 물
* 신청방법 : 참가비 입금 후 신청양식을 통해 신청해주세요! (입금 및 신청확인 후 안내문자를 보내드립니다)
* 참가비 : 10,000원 *링크가 열려있다면 아직 신청 가능한겁니당. 
(입금계좌 : 3333025023701 카카오뱅크 박은선)
* 문의: cheongyecheon@gmail.com,  리슨 인스타 디엠 https://www.instagram.com/listentothecity/

* 답사 코스
을지로3가역 4번출구 -> 수표동 공구유통상가 및 을지로 노가리골목 -> 입정동 철공소 골목 -> 산림동 도심제조업밀집지역 -> (시간이 맞는다면 인쇄소 골목 이동)`,
    ],
    address: "서울특별시 중구 수표동 11-2 4층 신도시 안",
  },
  {
    index: 3,
    id: SecondStudioId.WORKS,
    name: SecondStudioName.WORKS,
    schedules: [`16일(토) 11:00 - 15:00 (자유방문) (*WORKS MARKET)`],
    img: [works_1, works_2, works_3],
    member: [`WORKS`],
    text: [
      `워크스는 이연정, 이하림이 설립한, 서울을 기반으로 활동하는 그래픽 디자인 스튜디오입니다. 문화∙예술 영역, 기업과의 다양한 프로젝트를 수행하고 있습니다. 
이태원 작업실을 시작으로 12년간 수집하거나, 이제는 사용하지 않지만 좋은 주인을 찾았으면 하는 물건들을 내놓습니다. 실버랙이나 테이블, 테이블웨어, 과자전 상품 등을 판매할 예정입니다. worksmarket@gmail.com / @works.works `,
    ],
    address: "서울특별시 종로구 율곡로187 토토빌딩 5층",
  },
  {
    index: 4,
    id: SecondStudioId.joySomaHangaQoo,
    name: SecondStudioName.joySomaHangaQoo,
    schedules: [
      `15일(금) 11:00 - 19:00 (자유방문) (*소마킴 마사 예약방문 13:00 - 17:00)`,
      `16일(토) 11:00 - 19:00 (자유방문) (*소마킴 마사 예약방문 13:00 - 17:00)`,
      `17일(일) 11:00 - 19:00 (자유방문) (*소마킴 마사 예약방문 13:00 - 17:00)`,
      `17일(일) (*장다은 리코더 연주회 자유방문 18:00 - ?)`,
    ],
    bookingLink: `https://www.xxomakim.com/martha-reservation`,
    img: [
      jiwoo_1,
      jiwoo_2,
      somakim_1,
      somakim_2,
      heawon_1,
      heawon_2,
      jangdaeun_1,
      jangdaeun_2,
      jangdaeun_3,
    ],
    member: [`김지우`, `소마킴`, `이혜원`, `장다은`],
    text: [
      `조각, 설치, 퍼포먼스, 웹을 만듭니다. 숨은 곳곳에서 불쑥 튀어나오는 것들을 사랑하고요. 예술과 삶의 격차에 관해 더 많이 더 자주 이야기 나눠요. zuzokim@gmail.com / @jjjjjjoy_kim`,
      `음악가이자 미디어 아티스트입니다. 주로 기술과 스펙터클의 틈새에서 발생하는 오류와 유격을 중심으로 어트랙션과 댄스플로어, 속임수와 헛소리 같은 것을 만들고 재조립합니다. 작업실 9 to 6 출퇴근을 꿈꾸지만 90%의 확률로 퇴근에 실패하고 있으며, 부업으로는 을지수제비 전도사 활동을 하고 있습니다... www.xxomakim.com / @adjustmentlayer_`,
      `주로 그림을 그립니다. 소비 욕구를 재료 사는 것으로 풀지만 문제는 작업을 안 해서 조금 양심에 찔립니다. 비어가는 통장과 늘어나는 거북목 사이에서 어떻게든 뭔가 작업을 하려고 노력합니다. heawonlee3@gmail.com / @hang_a_e`,
      `퍼포먼스 작업을 해왔으며, 작업실에서는 주로 작업의 드로잉, 얼개들을 만드는 시간을 보낸다. 이번 오픈스튜디오에서는 최근 레인보우큐브에서 시작한 개인전 2023.9.8 ~ 9.24 <howling>과 이전 작업들을 하면서 생긴 드로잉들, 보관한 것들을 오픈하려 한다. chezjang@gmail.com / @qoo_jang`,
    ],
    address: "서울특별시 중구 수표로 10길 5-5, 금정빌딩 602호",
  },
  {
    index: 5,
    id: SecondStudioId.seum,
    name: SecondStudioName.seum,
    schedules: [`16일(토) 11:00 - 19:00 (자유방문)`],
    img: [seum_1, seum_2, seum_3, seum_4, seum_5],
    member: [``, `민선`, `상은`, `써니`, `탄지`, `수분`, `임지지`],
    text: [
      `공간 슴은 '유한책임회사 슴슴슴'의 멤버들이 작업실이자 다양한 창작활동을 할 수 있는 거점으로 활용하고자 마련한 공간이다. @seum_2022`,
      `삶과 진득하게 엉켜 있는 예술에 마음이 동한다. 최근에는 '안 예쁘'고 '안 아픈' 기획에 관심을 기울이고 있다. @3_2_5_4_9`,
      `주로 기획하며, 종종 글을 읽고 쓴다. 내가 하는 일에 내 삶의 고민과 즐거움이 담기기를 바란다. oio54100675@gmail.com`,
      `공간에서 사람들이 관계맺는 방법에 관심이 많다. 도시와 시골을 오가며 두지역살이를 실천하는 행동파. sunnycicici@naver.com`,
      `모든 것에 이유를 붙이는 세상에서 쓸데없는 걸 하고싶은 그냥 사람. 사회의 논리에서 벗어나 일탈과 쾌감을 주는 예술이 좋아서 여기에서 이러고 있는 중. @eeeeehyun`,
      `함께하는 배움에 관심이 많다. 어떻게하면 더 많은 사람들과 더 재밌게 배울 수 있을지 고민하고, 계획하는 것을 좋아한다. @subbb_in`,
      `이야기에 저항하는 이야기를 만든다. 요즘 깨달은 것, 나는 바쁘면 생기가 도는 사람이다. @imzizi_gathering`,
    ],
    address: "서울특별시 삼선교로23가길 30 B1",
  },
  {
    index: 6,
    id: SecondStudioId.nest,
    name: SecondStudioName.nest,
    schedules: [
      `16일(토) 16:00 - 19:00 (예약 방문) (*하단 상세 참고)`,
      `17일(일) 11:00 - 21:00 (자유방문) (*하단 상세 참고)`,
    ],
    bookingLink: `https://event-us.kr/doodlefingers/event/71020`,
    img: [nest_1, nest_2, nest_3, nest_4, nest_5],
    member: [`둥지`],
    text: [
      `둥지는 2022년 1월부터 크리에이티브 코딩 커뮤니티와 교육을 위해 열린 공간입니다.
호스트 : 정효 @doodlefingers
실시간 컴퓨터 아티스트로 디지털 감각의 다양한 확장과 즐거움을 탐구하고 있다. 융합 교육, 모션그래픽 프로그래밍, 게임제작 등 다양한 분야에서 활동중이다.

공동기획자 : 고윤서 @yesyunyun
고윤서는 서울을 기반으로 크리에이티브 디자인 스튜디오 YYY를 운영하는 그래픽디자이너이다. 주로 가상과 현실 사이에서 텍스트와 그래픽, 그리고 사람이 상호 작용하는 방법을 탐구한다.

<16일(토) 프로그램 (예약방문)>
[16:00 - 19:00] Parts Parts : 모션포스터 (https://event-us.kr/doodlefingers/event/71020)
둥지 그리고 그이전까지의 두들핑거스의 기획들과 행사를 위해 작업한 모션포스터 그래픽들의 제작과정 뒷이야기를 소개합니다. 발표 후 뒷풀이 네트워킹이 있습니다. (참가비 1만원)

<17일(일) 프로그램 (자유방문)>
[11:00 - 21:00] 스튜디오 방문 및 상시프로그램 자유롭게 참여 가능합니다.
협소한 공간으로 인해 10인 이상 참석이 어려울 수 있으니, 참고해주시고 일부 시간대에 양해부탁드립니다. 

[11:00 - 13:00] 체험워크샵 <페나키스토스코프 만들어보기> (고윤서)
페나키스토스코프 애니메이션을 손그림으로 그려봅니다. 웹을 활용해서 디지털상의 애니메이션으로 만들어드려요. 누구든 참여가능합니다.
[15:00] 라이브코딩 (정효)
[17:00 - 18:00] 토크 (고윤서/정효)
[19:00] 라이브코딩 (정효)`,
    ],
    address:
      "서울특별시 강남구 역삼동 822-7 목화밀라트 오피스텔 401호 (강남역 12번출구 부근)",
  },
  {
    index: 7,
    id: SecondStudioId.paxhupyeahjiiin,
    name: SecondStudioName.paxhupyeahjiiin,
    schedules: [
      `15일(금) 11:00 - 19:00 (자유방문)`,
      `16일(토) 11:00 - 19:00 (자유방문)`,
      `17일(일) 11:00 - 19:00 (자유방문)`,
    ],
    img: [pmy_1, pmy_2, pmy_3, pmy_4, kimheekyung_1, hongyejin_1],
    member: [`박민영`, `김희경`, `홍예진`],
    text: [
      `친족이나 혼인이 아니더라도 서로를 돌보는 사람들을 인터뷰하고, 주로 영상을 제작합니다. 개인전 《우리의 미래가 협소하지 않기를 바라며》(Hall1, 2022)를 열었으며, 《Win in Wings》(Gallery175, 2023), 《Every mosquito feels the same》(This is not a church, 2022)에 참여했습니다. 이번 오픈스튜디오에서는 지난 작업들을 간단히 보여주며, 앞으로의 작업에 관한 이야기를 나눌 예정입니다. @p_axpax`,
      `'조형을 통한 더 나은 문제 접근’이라는 관점으로 디자인을 정의하고 실천하는 창작자입니다. 다소 정복적 관점인 해결과 대치되는 개념으로서 ‘문제(Problem)’보다는, ‘고통과 차별, 혐오와 맞붙어있는’ 개념으로서 ‘문제(Issue)’에 디자인이 어떻게, 어디까지 작동할 수 있는지에 대해 관심이 많습니다. 현재는 음식을 통해 배제되어본 경험을 환대의 음식을 만들어 승화시키는 프로젝트 <레프트 푸드Left Food> 를 준비하느라 바쁩니다. 어떻게 구현될지 궁금하시다면 방문해주세요. @hupngemlights2`,
      `눈을 끄는 것에 대해 호기심을 가지고 작업을 하고 있습니다. 중첩되어 다르게 보이거나 조금의 변화로 새롭게 보이는 것들을 좋아하면서도 어렵다고 느낍니다. 최근에는 weather라는 팀을 만들어 프로젝트를 기획하고 감독하고 있습니다. https://wwweather.cargo.site / @yeahjiii`,
    ],
    address: "서울특별시 퇴계로 275 영수빌딩 202호",
  },
  {
    index: 8,
    id: SecondStudioId.macadamiaOh,
    name: SecondStudioName.macadamiaOh,
    schedules: [`15일(금) 11:00 - 16:00 (예약방문)`],
    bookingLink: `https://forms.gle/ZLVd9dXiVEoaQpXc7`,
    img: [macadamiaoh],
    member: [`마카다미아 오`],
    text: [
      `마카다미아 오는, 서울에서 활동하는 그래픽 디자이너입니다. 정해진 규격 안에서 자유롭고 아름답게 정보를 수납하는 방법에 대해 고민합니다. 작업물을 만들 때마다 스스로에게 '20년 후에 봐도 좋을까?' 질문하며 디자인하고 있습니다.

마카다미아 오는 꽤 많은 책을 디자인했습니다. 그러나 SNS 상으로 책을 내밀하게 살펴보기 어렵죠. 제가 그동안 책을 어떤 디자인으로 풀어왔는지 궁금하신 분, 또는 저에게 앞으로 책 디자인을 맡기고 싶은 분들을 위해 제가 디자인한 책들을 모아 볼 수 있는 자리를 마련했습니다. 9월 15일 오전 11시부터 오후 4시까지 스튜디오를 오픈합니다. 정답게 인사 나누어요. @macadamiaoh`,
    ],
    address: "서울특별시 종로구 율곡로55 502호",
  },
  {
    index: 9,
    id: SecondStudioId.meanyounglamb,
    name: SecondStudioName.meanyounglamb,
    schedules: [
      `15일(금) 13:00 - 19:00 (자유방문)`,
      `16일(토) 13:00 - 19:00 (자유방문)`,
      `17일(일) 13:00 - 19:00 (자유방문)`,
    ],
    img: [
      bulldozer_1,
      bulldozer_2,
      bulldozer_3,
      bulldozer_4,
      dapalm_1,
      dapalm_2,
      dapalm_3,
      dapalm_4,
      dapalm_5,
    ],
    member: [`불도저`],
    text: [
      `그래픽 디자이너 양민영이 운영하는 디자인 스튜디오 및 종합 기획사. 옷과 스타일에 관한 잡지 ‹쿨›, 옷장 속 안입는 옷을 정리하는 행사 '옷정리’를 만든다. 최근에는 무엇이든 팔 수 있는 공간 ‘다팜’을 오픈했다. 주로 5층 한쪽에서 디자인 및 각종 일을 하고, 종종 다른 한쪽인 '다팜'에서 행사를 열며, 공간을 관리한다. @meanyounglamb @dapalm__

무엇이든 팔 수 있는 공간 '다팜'을 소개합니다. 팝업스토어, 출간기념회, 쇼케이스, 전시 등... 어떤 용도로든 사용할 수 있습니다. 의류, 책 등 다양한 물건을 디스플레이할 수 있는 자체 집기 보유! 2, 3호선 을지로 3가 11번 출구 바로 앞! 편하게 들러 공간을 둘러보세요. 대관 문의 -> info@dapalm.kr`,
    ],
    address: "서울시 중구 을지로108 5층",
  },
  {
    index: 10,
    id: SecondStudioId.sebalsot,
    name: SecondStudioName.sebalsot,
    schedules: [
      `15일(금) 18:00 - 21:00 (자유방문) (*장면산책 예약방문 17:00 - 17:30)`,
      `16일(토) 18:00 - 21:00 (자유방문) (*장면산책 예약방문 17:00 - 17:30)`,
    ],
    bookingLink: `https://forms.gle/RumLCqYvnq4U82dT9`,
    img: [sebalsot_1, sebalsot_2, sebalsot_3, sebalsot_4, sebalsot_5],
    member: [`세발솥`, `여영은`, `정혜린`, `이소정`],
    text: [
      `청동 세 발 솥은 고구려 때 만들어진 발이 세 개 달린 솥입니다. 자기만의 방식으로 쓰기 하는 사람 세 명이 모여서 허구한 날 보고 들은 것들을 솥 안에 담아 놓습니다. 적당히 모이면 뭉근하게 끓입니다.`,
      `촬영 장비와 온갖 전선과 점점 쌓여만 가는 외장하드 그리고 거대한 티브이 사이에 묻혀 살아가고 있다. 이것을 업보라 여기고 이 기계 장치들을 가지고 먹고살고 작업도 한다. 영상 촬영, 편집, 아날로그 필름 현상 등 영상 장비와 관련한 담소나 정보 교환 나누고 싶으신 분 환영합니다. @yyeoooo`,
      `주로 공연을 만들고 가끔 소리를 만듭니다. 번개를 좋아하고 듣는 걸 좋아합니다. 이야기 앞뒤에서 일어나는 오차들에 관심이 많습니다. 더 많은 오차를 꿈꾸면서 <장면산책>을 준비했습니다. 산책 보러, 산책하러 오세요. 여기도 거기도 온통 극장입니다. @crowly_olily_salad`,
      `이것저것 고민하기 위해 작업실에 갑니다. 글을 쓸 때도 있고 안 쓸 때도 많습니다. 2023년 가을날 저녁 낯선 곳에서 술(혹은 음료) 한 잔 기울이기 좋죠. 놀러 오세요.  @hheiichh`,
    ],
    address: "서울특별시 성북구 지봉로20길 60-18 1층",
  },
  {
    index: 11,
    id: "open-studio-time",
    name: "open-studio-time",
    schedules: [``],
    img: [``],
    member: [``],
    text: [``],
    address: ``,
  },
];

export type SecondStudioLocation = {
  name: string;
  id: string;
  latitude: number;
  longtitude: number;
  src: string;
  address: string;
  link: string;
};

export const STUDIO_LOCATION_LIST: SecondStudioLocation[] = [
  {
    name: SecondStudioName.Brownbuilding,
    id: SecondStudioId.Brownbuilding,
    latitude: 37.5628621,
    longtitude: 126.9283691,
    src: "/2023/mark1.png",
    address: "서울특별시 서대문구 연희로 52-6, 1층 브라운빌딩",
    link: "https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%9C%EB%8C%80%EB%AC%B8%EA%B5%AC%20%EC%97%B0%ED%9D%AC%EB%A1%9C%2052-6%2C%201%EC%B8%B5%20%EB%B8%8C%EB%9D%BC%EC%9A%B4%EB%B9%8C%EB%94%A9/address/14129602.9627736,4517854.5473329,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%9C%EB%8C%80%EB%AC%B8%EA%B5%AC%20%EC%97%B0%ED%9D%AC%EB%A1%9C%2052-6,new?c=19.00,0,0,0,dh&isCorrectAnswer=true",
    // ...DISGUISED_LOCATION,
  },

  {
    name: SecondStudioName.listentotheCity,
    id: SecondStudioId.listentotheCity,
    latitude: 37.5678105,
    longtitude: 126.990736,
    src: "/2023/mark2.png",
    address: "서울특별시 중구 수표동 11-2 4층 신도시 안",
    link: "https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%A4%91%EA%B5%AC%20%EC%88%98%ED%91%9C%EB%8F%99%2011-2%204%EC%B8%B5%20%EC%8B%A0%EB%8F%84%EC%8B%9C/address/14136543.1318993,4518551.5276915,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%A4%91%EA%B5%AC%20%EC%88%98%ED%91%9C%EB%8F%99%2011-2,jibun?c=19.00,0,0,0,dh&isCorrectAnswer=true",
    // latitude: DISGUISED_LOCATION.latitude,
    // longtitude: DISGUISED_LOCATION.longtitude + 0.002,
  },
  {
    name: SecondStudioName.WORKS,
    id: SecondStudioId.WORKS,
    latitude: 37.5761491,
    longtitude: 126.9995931,
    src: "/2023/mark3.png",
    address: "서울특별시 종로구 율곡로187 토토빌딩 5층",
    link: "https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%A2%85%EB%A1%9C%EA%B5%AC%20%EC%9C%A8%EA%B3%A1%EB%A1%9C187%20%ED%86%A0%ED%86%A0%EB%B9%8C%EB%94%A9%205%EC%B8%B5/address/14137533.3187699,4519719.7540083,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%A2%85%EB%A1%9C%EA%B5%AC%20%EC%9C%A8%EA%B3%A1%EB%A1%9C%20187,new?c=19.00,0,0,0,dh&isCorrectAnswer=true",
    // latitude: DISGUISED_LOCATION.latitude,
    // longtitude: DISGUISED_LOCATION.longtitude + 0.001,
  },
  {
    name: SecondStudioName.joySomaHangaQoo,
    id: SecondStudioId.joySomaHangaQoo,
    latitude: 37.5638424,
    longtitude: 126.9906027,
    src: "/2023/mark4.png",
    address: "서울특별시 중구 수표로 10길 5-5, 금정빌딩 602호",
    link: "https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%A4%91%EA%B5%AC%20%EC%88%98%ED%91%9C%EB%A1%9C%2010%EA%B8%B8%205-5%2C%20%EA%B8%88%EC%A0%95%EB%B9%8C%EB%94%A9%20602%ED%98%B8/address/14136530.730908,4517994.5323271,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%A4%91%EA%B5%AC%20%EC%88%98%ED%91%9C%EB%A1%9C10%EA%B8%B8%205-5,new?c=19.00,0,0,0,dh&isCorrectAnswer=true",
    // latitude: DISGUISED_LOCATION.latitude,
    // longtitude: DISGUISED_LOCATION.longtitude + 0.006,
  },
  {
    name: SecondStudioName.seum,
    id: SecondStudioId.seum,
    latitude: 37.5896601,
    longtitude: 127.0128363,
    src: "/2023/mark5.png",
    address: "서울특별시 삼선교로23가길 30 B1",
    link: "https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%82%BC%EC%84%A0%EA%B5%90%EB%A1%9C23%EA%B0%80%EA%B8%B8%2030%20B1/address/14139005.3409244,4521619.8523799,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%B1%EB%B6%81%EA%B5%AC%20%EC%82%BC%EC%84%A0%EA%B5%90%EB%A1%9C23%EA%B0%80%EA%B8%B8%2030,new?c=19.00,0,0,0,dh&isCorrectAnswer=true",
    // latitude: DISGUISED_LOCATION.latitude,
    // longtitude: DISGUISED_LOCATION.longtitude + 0.003,
  },
  {
    name: SecondStudioName.nest,
    id: SecondStudioId.nest,
    latitude: 37.4989741,
    longtitude: 127.0295348,
    src: "/2023/mark6.png",
    address:
      "서울특별시 강남구 역삼동 822-7 목화밀라트 오피스텔 401호 (강남역 12번출구 부근)",
    link: "https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EA%B0%95%EB%82%A8%EA%B5%AC%20%EC%97%AD%EC%82%BC%EB%8F%99%20822-7%20%EB%AA%A9%ED%99%94%EB%B0%80%EB%9D%BC%ED%8A%B8%20%EC%98%A4%ED%94%BC%EC%8A%A4%ED%85%94%20401%ED%98%B8%20(%EA%B0%95%EB%82%A8%EC%97%AD%2012%EB%B2%88%EC%B6%9C%EA%B5%AC%20%EB%B6%80%EA%B7%BC)/address/14140866.1130047,4508889.2407653,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EA%B0%95%EB%82%A8%EA%B5%AC%20%EC%97%AD%EC%82%BC%EB%8F%99%20822-7,jibun?c=19.00,0,0,0,dh&isCorrectAnswer=true",
    // latitude: DISGUISED_LOCATION.latitude,
    // longtitude: DISGUISED_LOCATION.longtitude + 0.005,
  },
  {
    name: SecondStudioName.paxhupyeahjiiin,
    id: SecondStudioId.paxhupyeahjiiin,
    latitude: 37.5634385,
    longtitude: 127.0020856,
    src: "/2023/mark7.png",
    address: "서울특별시 퇴계로 275 영수빌딩 202호",
    link: "https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%ED%87%B4%EA%B3%84%EB%A1%9C%20275%20%EC%98%81%EC%88%98%EB%B9%8C%EB%94%A9%20202%ED%98%B8/address/14137797.6357688,4517925.2277244,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%A4%91%EA%B5%AC%20%ED%87%B4%EA%B3%84%EB%A1%9C%20275,new?c=19.00,0,0,0,dh&isCorrectAnswer=true",
    // latitude: DISGUISED_LOCATION.latitude,
    // longtitude: DISGUISED_LOCATION.longtitude + 0.007,
  },
  {
    name: SecondStudioName.macadamiaOh,
    id: SecondStudioId.macadamiaOh,
    latitude: 37.5767754,
    longtitude: 126.9853799,
    src: "/2023/mark8.png",
    address: "서울특별시 종로구 율곡로55 502호",
    link: "https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%A2%85%EB%A1%9C%EA%B5%AC%20%EC%9C%A8%EA%B3%A1%EB%A1%9C55%20502%ED%98%B8/address/14135952.1033268,4519805.0969332,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%A2%85%EB%A1%9C%EA%B5%AC%20%EC%9C%A8%EA%B3%A1%EB%A1%9C%2055,new?c=19.00,0,0,0,dh&isCorrectAnswer=true",
    // latitude: DISGUISED_LOCATION.latitude,
    // longtitude: DISGUISED_LOCATION.longtitude + 0.007,
  },
  {
    name: SecondStudioName.meanyounglamb,
    id: SecondStudioId.meanyounglamb,
    latitude: 37.5660483,
    longtitude: 126.9899614,
    src: "/2023/mark9.png",
    address: "서울시 중구 을지로108 5층",
    link: "https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%EC%8B%9C%20%EC%A4%91%EA%B5%AC%20%EC%9D%84%EC%A7%80%EB%A1%9C108%205%EC%B8%B5/address/14136459.1858713,4518304.2248865,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%A4%91%EA%B5%AC%20%EC%9D%84%EC%A7%80%EB%A1%9C%20108,new?c=19.00,0,0,0,dh&isCorrectAnswer=true",
    // latitude: DISGUISED_LOCATION.latitude,
    // longtitude: DISGUISED_LOCATION.longtitude + 0.007,
  },
  {
    name: SecondStudioName.sebalsot,
    id: SecondStudioId.sebalsot,
    latitude: 37.5821476,
    longtitude: 127.0185725,
    src: "/2023/mark10.png",
    address: "서울특별시 성북구 지봉로20길 60-18 1층",
    link: "https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%B1%EB%B6%81%EA%B5%AC%20%EC%A7%80%EB%B4%89%EB%A1%9C20%EA%B8%B8%2060-18%201%EC%B8%B5/address/14139643.2127386,4520566.8513101,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%B1%EB%B6%81%EA%B5%AC%20%EC%A7%80%EB%B4%89%EB%A1%9C20%EA%B8%B8%2060-18,new?c=19.00,0,0,0,dh&isCorrectAnswer=true",
    // latitude: DISGUISED_LOCATION.latitude,
    // longtitude: DISGUISED_LOCATION.longtitude + 0.004,
  },
];
