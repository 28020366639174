/** @jsxImportSource @emotion/react */
// import { useState, MouseEvent } from "react";
import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import posterImgSrc from "../../../assets/2023/design-assets/poster.jpg";

type Info = {
  name: string;
  id: string;
  subnames?: string[];
  subids?: string[];
};

const STUDIO_LIST: Info[] = [
  { name: "Brown building", id: "brownbuilding.kr" },
  {
    name: `WORKS`,
    id: "works.works",
  },
  {
    name: "리슨투더시티(Listen to the City)",
    id: "listentothecity",
  },
  { name: "공간 슴", id: "seum_2022" },
  {
    name: `세발솥`,
    subnames: ["정혜린", "이소정", "여영은"],
    id: "sebalsot",
    subids: ["crowly_olily_salad", "hheiichh", "yyeoooo"],
  },
  { name: "둥지", id: "nest.cc" },
  { name: "마카다미아 오", id: "macadamiaoh" },
  { name: "불도저", id: "meanyounglamb" },
  { name: "김지우", id: "jjjjjjoy_kim" },
  { name: "소마킴", id: "soma_kim" },
  { name: "장다은", id: "qoo_jang" },
  { name: "이혜원", id: "hang_a_e" },
  { name: "김희경", id: "hupngemlights2" },
  { name: "박민영", id: "p_axpax" },
  { name: "홍예진", id: "yeahjiiin" },
];

const CREDIT_LIST: Info[] = [
  { name: "김지우", id: "jjjjjjoy_kim" },
  { name: "소마킴", id: "soma_kim" },
  { name: "이혜원", id: "hang_a_e" },
];

const DESIGNER_LIST: Info[] = [
  {
    name: "양민영(불도저)",
    id: "meanyounglamb",
  },
];

const DEV_LIST: Info[] = [{ name: "김지우", id: "jjjjjjoy_kim" }];

const TitleStyle = css`
  white-space: nowrap;
  font-size: 18px;
`;

const captionStyle = css`
  font-size: 12px;
  white-space: pre-wrap;
`;

const imgStyle = css`
  width: 100%;
  @media (min-width: 360px) {
    width: 350px;
  }
  @media (min-width: 720px) {
    width: 500px;
  }
  @media (min-width: 1440px) {
    width: 900px;
  }
  aspect-ratio: 4 / 5;
`;

const paragraphStyle = css`
  word-break: keep-all;
  gap: 8px;
  @media (min-width: 360px) {
    width: 340px;
  }
  @media (min-width: 720px) {
    width: 500px;
  }
  @media (min-width: 1440px) {
    width: 800px;
  }
`;

const linkStyle = css`
  display: flex;
  align-items: center;
  &:link {
    color: #000;
    text-decoration: none;
  }
  &:visited {
    color: #000;
    text-decoration: none;
  }
  &:hover {
    color: #fe0000;
    text-decoration: none;
  }
`;

interface IntroProps {}

const Intro = (props: IntroProps) => {
  return (
    <Stack
      css={css`
        align-items: center;
        gap: 40px;
        padding: 16px 24px;
        margin-bottom: 24px;
      `}
    >
      <img src={posterImgSrc} css={imgStyle} alt="open_studio_poster" />
      <Stack css={paragraphStyle}>
        <Typography css={TitleStyle}>
          <b>오픈스튜디오 OPEN STUDIO</b>
        </Typography>
        <Typography>2023.09.15(금) - 2023.09.17(일)</Typography>
        <Stack direction="row" alignItems="baseline" gap="4px">
          <Typography>11:00~19:00</Typography>
          <Typography css={captionStyle}>
            *스튜디오마다 조금씩 다릅니다.
          </Typography>
        </Stack>
        <Typography style={{ fontSize: "14px", lineHeight: 1.8 }}>
          오픈스튜디오가 돌아왔습니다. 서로의 근방에서 시작한 2022년에 이어
          올해는 보다 넓은 활동범위로 확장해 진행합니다. 15일~17일 3일간 각자
          작업실을 열고, 찾아오는 방문객들을 환영합니다.
        </Typography>
      </Stack>

      <Stack css={paragraphStyle}>
        <Typography>
          <b>참여</b>
        </Typography>
        {STUDIO_LIST.map((v, i) => {
          return (
            <Stack direction="row" gap="4px" key={i}>
              <a
                href={`https://www.instagram.com/${v.id}`}
                target="_blank"
                rel="noopener noreferrer"
                css={linkStyle}
              >
                {v.name}
              </a>
              {v.name === "세발솥" && (
                <Stack direction="row" gap="0">
                  {`(`}
                  {v.subnames?.map((subname, i) => (
                    <>
                      <a
                        key={i}
                        href={`https://www.instagram.com/${v.subids?.[i]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        css={linkStyle}
                      >
                        {subname}
                      </a>
                      {i !== 2 && "/"}
                    </>
                  ))}
                  {`)`}
                </Stack>
              )}
            </Stack>
          );
        })}
      </Stack>
      <Stack css={paragraphStyle}>
        <Typography>
          <b>기획/진행</b>
        </Typography>
        {CREDIT_LIST.map((v, i) => (
          <Stack direction="row" gap="4px" key={i}>
            <a
              href={`https://www.instagram.com/${v.id}`}
              target="_blank"
              rel="noopener noreferrer"
              css={linkStyle}
            >
              {v.name}
            </a>
          </Stack>
        ))}
      </Stack>
      <Stack css={paragraphStyle}>
        <Typography>
          <b>그래픽 디자인</b>
        </Typography>
        {DESIGNER_LIST.map((v, i) => (
          <Stack direction="row" gap="4px" key={i}>
            <a
              href={`https://www.instagram.com/${v.id}`}
              target="_blank"
              rel="noopener noreferrer"
              css={linkStyle}
            >
              {v.name}
            </a>
          </Stack>
        ))}
      </Stack>
      <Stack css={paragraphStyle}>
        <Typography>
          <b>웹</b>
        </Typography>
        {DEV_LIST.map((v, i) => (
          <Stack direction="row" gap="4px" key={i}>
            <a
              href={`https://www.instagram.com/${v.id}`}
              target="_blank"
              rel="noopener noreferrer"
              css={linkStyle}
            >
              {v.name}
            </a>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default Intro;
