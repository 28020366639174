import four00viewSrc from "../../../assets/images/contentsImgs/400VIEW_web.png";

import bomaSrc1 from "../../../assets/images/contentsImgs/boma_web1.png";
import bomaSrc2 from "../../../assets/images/contentsImgs/boma_web2.png";
import bomaSrc3 from "../../../assets/images/contentsImgs/boma_web3.png";
import bomaSrc4 from "../../../assets/images/contentsImgs/boma_web4.png";
import bomaSrc5 from "../../../assets/images/contentsImgs/boma_web5.png";

import relaySrc from "../../../assets/images/contentsImgs/relay_web.png";

import LtcSrc1 from "../../../assets/images/contentsImgs/Ltc_web1.png";
import LtcSrc2 from "../../../assets/images/contentsImgs/Ltc_web2.png";
import LtcSrc3 from "../../../assets/images/contentsImgs/Ltc_web3.png";
import LtcSrc4 from "../../../assets/images/contentsImgs/Ltc_web4.png";
import LtcSrc5 from "../../../assets/images/contentsImgs/Ltc_web5.png";

import uljiroSrc from "../../../assets/images/contentsImgs/uljiro_web.png";
import mojoSrc from "../../../assets/images/contentsImgs/mojo_web.png";
import bdzSrc1 from "../../../assets/images/contentsImgs/bdz_web1.png";
import bdzSrc2 from "../../../assets/images/contentsImgs/bdz_web2.png";
import bdzSrc3 from "../../../assets/images/contentsImgs/bdz_web3.png";
import bdzSrc4 from "../../../assets/images/contentsImgs/bdz_web4.png";
import todaySrc from "../../../assets/images/contentsImgs/today_web.png";
import fychSrc1 from "../../../assets/images/contentsImgs/fych_web1.png";
import fychSrc2 from "../../../assets/images/contentsImgs/fych_web2.png";
import fychSrc3 from "../../../assets/images/contentsImgs/fych_web3.png";
import fychSrc4 from "../../../assets/images/contentsImgs/fych_web4.png";

import wotlSrc1 from "../../../assets/images/contentsImgs/wotl_web1.png";
import wotlSrc2 from "../../../assets/images/contentsImgs/wotl_web2.png";
import wotlSrc3 from "../../../assets/images/contentsImgs/wotl_web3.png";

import joySrc1 from "../../../assets/images/contentsImgs/joy_web1.jpg";
import joySrc2 from "../../../assets/images/contentsImgs/joy_web2.jpg";
import joySrc3 from "../../../assets/images/contentsImgs/joy_web3.jpg";
import somaSrc1 from "../../../assets/images/contentsImgs/soma_web1.png";
import somaSrc2 from "../../../assets/images/contentsImgs/soma_web2.png";
import somaSrc3 from "../../../assets/images/contentsImgs/soma_web3.png";
import lhwSrc1 from "../../../assets/images/contentsImgs/lhw_web1.png";
import lhwSrc2 from "../../../assets/images/contentsImgs/lhw_web2.png";
import lhwSrc3 from "../../../assets/images/contentsImgs/lhw_web3.png";

import khgSrc1 from "../../../assets/images/contentsImgs/khg_web1.png";
import khgSrc2 from "../../../assets/images/contentsImgs/khg_web2.png";
import khgSrc3 from "../../../assets/images/contentsImgs/khg_web3.png";
import khgSrc4 from "../../../assets/images/contentsImgs/khg_web4.png";
import khgSrc5 from "../../../assets/images/contentsImgs/khg_web5.png";
import khgSrc6 from "../../../assets/images/contentsImgs/khg_web6.png";
import pmySrc1 from "../../../assets/images/contentsImgs/pmy_web1.png";
import pmySrc2 from "../../../assets/images/contentsImgs/pmy_web2.png";
import pmySrc3 from "../../../assets/images/contentsImgs/pmy_web3.png";
import pmySrc4 from "../../../assets/images/contentsImgs/pmy_web4.png";
import pmySrc5 from "../../../assets/images/contentsImgs/pmy_web5.png";
import pmySrc6 from "../../../assets/images/contentsImgs/pmy_web6.png";
import uminSrc1 from "../../../assets/images/contentsImgs/umin_web1.png";
import uminSrc2 from "../../../assets/images/contentsImgs/umin_web2.png";
import uminSrc3 from "../../../assets/images/contentsImgs/umin_web3.png";
import uminSrc4 from "../../../assets/images/contentsImgs/umin_web4.png";
import uminSrc5 from "../../../assets/images/contentsImgs/umin_web5.png";
import uminSrc6 from "../../../assets/images/contentsImgs/umin_web6.png";

export enum StudioName {
  four00view = "400VIEW",
  boma = "boma",
  listentotheCity = "listentotheCity",
  relay = "relay",
  womanOpenTechLab = "womanOpenTechLab",
  uljiroEtc = "uljiroEtc",
  joySomaHanga = "joySomaHanga",
  kimParkYu = "kimParkYu",
}

export type StudioLocation = {
  name: string;
  id: string;
  latitude: number;
  longtitude: number;
};

export type StudioContent = {
  index: number;
  name: string;
  id: string;
  img: string[];
  imgCaption?: string[];
  member: string[];
  text: string[];
  address: string;
  schedule: string[];
};

export const StudioColor: Record<StudioName, string> = {
  [StudioName.four00view]: "#c69c6d",
  [StudioName.boma]: "#ff00ff",
  [StudioName.listentotheCity]: "#1bb7ed",
  [StudioName.relay]: "#ff3c00",
  [StudioName.womanOpenTechLab]: "#17bc69",
  [StudioName.uljiroEtc]: "#808080",
  [StudioName.joySomaHanga]: "#0000ff",
  [StudioName.kimParkYu]: "#7c29ff",
};

export const STUDIO_CONTENTS_LIST: StudioContent[] = [
  {
    index: 0,
    name: "400VIEW",
    id: StudioName.four00view,
    schedule: [`24일(토) 12:00 - 19:00`, `25일(일) 12:00 - 19:00`],
    img: [four00viewSrc],
    // imgCaption: ["400VIEW"],
    member: ["400VIEW"],
    text: [
      `
영상 촬영을 주로 진행하는 400VIEW에게 스튜디오는 촬영 이전의 소품을 제작하는 작업실이자 이후 촬영물들을 편집하는 편집실, 그리고 그에 관한 불확실한 대화를 나누는 공간이다. 이번 오픈 스튜디오는 영상 촬영 이전의 단계에서 만들어지는 것들을 중심으로 구성되며, 그 자체로는 완결이 아닌 상태로 다음 단계에 포함될 부분으로써의 소품들을(혹은 물건들?)을 공개한다.`,
    ],
    address: `서울특별시 중구 퇴계로 39길 7 
윤미빌딩 203호`,
  },
  {
    index: 1,
    name: `boma pak studio(fldjf studio, WTM decoration & boma, Matter et cetera)`,
    id: StudioName.boma,
    schedule: [`25일(일) 12:00 - 18:00`],
    img: [bomaSrc1, bomaSrc2, bomaSrc3, bomaSrc4, bomaSrc5],
    // imgCaption: ["boma", "boma", "boma", "boma", "(pre)Matter Service"],
    member: [
      `boma pak studio (fldjf studio, WTM decoration & boma, Matter et cetera)`,
    ],
    text: [
      `
박보마 스튜디오는 박보마의 다양한 아이덴티티를 위한 작업과 프로젝트를 만들고 구상하는 곳을 임의로 부르는 이름입니다.

➔ 박보마 스튜디오는 오픈스튜디오와 더불어, 현재 준비 중인 향 컨설팅 아뜰리에 'Matter'의 첫 번째 테마 향인 'PRW.100'을 엿볼 수 있는 프리뷰 서비스 '(pre)Matter'를 선보입니다.

샘플 카드에 담은 PRW.100을 경험하세요. 한정 수량이며, 선착순으로 서비스합니다.`,
    ],
    address: "서울특별시 중구 을지로 103, 602호(옥탑)",
  },
  {
    index: 2,
    name: "리슨투더시티(Listen to the City)",
    id: StudioName.listentotheCity,
    schedule: [`24일(토) 14:00 - 19:00`, `25일(일) 14:00 - 19:00`],
    img: [LtcSrc5, LtcSrc1, LtcSrc2, LtcSrc3, LtcSrc4],
    // imgCaption: [
    //   "Listen to the City(리슨투더시티)",
    //   "Listen to the City(리슨투더시티)",
    //   "Listen to the City(리슨투더시티)",
    //   "Listen to the City(리슨투더시티)",
    //   "Listen to the City(리슨투더시티)",
    // ],
    member: ["리슨투더시티(Listen to the City)"],
    text: [
      `
리슨투더시티는 보이지 않는 주변의 목소리와 존재를 드러내는 작업들을 2009년부터 하고 있습니다. 2015년 부터 을지로 신도시 4층에서 작업실을 운영하고 있으며 청계천을지로보존연대, 을지OB베어 공대위 활동도 함께하고 있습니다.

➔ 리슨투더시티 오픈 스튜디오에서는 그동안 리슨투더시티가 발간한 책자들을 30% 할인된 책자들을 구매하실 수 있으며 절판된 책들도 구경하실 수 있습니다. 맛있는 핸드 드립 커피를 판매할 예정입니다.`,
    ],
    address: "서울특별시 중구 수표동 11-2 4층",
  },
  {
    index: 3,
    name: "릴레이",
    id: StudioName.relay,
    schedule: [`24일(토) 13:00 - 18:00`],
    img: [relaySrc],
    member: ["릴레이"],
    text: [
      `
릴레이는 김재환, 신민규, 김형도로 구성된 그래픽디자인 스튜디오다. 브랜딩, 웹디자인 및 개발, 전시, 출판물 등 그래픽디자인의 전반적인 작업을 수행하며, 국내 여러 문화기관 및 독립 큐레이터, 동시대 작가들과 활발하게 협업하고 있다.`,
    ],
    address: "릴레이 방문예약하기",
  },
  {
    index: 4,
    name: `여성을 위한 열린 기술랩(WOMAN OPEN TECH LAB)`,
    id: StudioName.womanOpenTechLab,
    schedule: [`25일(일) 13:00 – 19:00`],
    img: [wotlSrc1, wotlSrc2, wotlSrc3],
    // imgCaption: ["여성을 위한 열린 기술랩", "체험 워크샵", "체험 워크샵"],
    member: [`여성을 위한 열린 기술랩 (WOMAN OPEN TECH LAB)`],
    text: [
      `
2017년 결성된 여성을 위한 열린 기술랩(이하 여성기술랩)은 오랜시간 도심제조업이 자생적으로 형성되어온 을지로에 위치해있습니다. 

을지로라는 물리적 공간 뿐만 아니라, 메이커 문화, 미디어아트 등 기술을 활용하는 영역에 만연한 남성편향적이고 위계적인 문화에 문제의식을 갖고, '여성'과 '기술'이라는 두 키워드의 결합을 선언적 의미로 제시합니다. 워크숍, 전시, 세미나, 강연, 연구모임 등 다양한 활동을 통해 기술에 대한 흥미와 리터러시를 키우고, 주체적인 사고와 새로운 관점으로서 기술의 젠더적 접근-페미니즘의 기술적 실천을 도모합니다.

www.womanopentechlab.kr
@womanopentechlab

➔ 여성기술랩에서는 25일(일) 낮 1시-저녁 7시까지 랩을 개방하여, 랩의 시그니쳐 키트 2종을 자유롭게 체험하는 자리를 만듭니다. 바느질로 회로를 구성해보는 'E-textile 입문키트'와 키링형태의 특별한 PCB 제작 워크숍인 'HEARTBEAT 하트-빛' 중에서 선택하여 체험이 가능합니다. 가벼운 마음으로 오셔서 둘러보고 이야기 나누어도 좋습니다. 키트는 각각 10개 한정이며, 선착순으로 마감합니다. 신청은 따로 없으니 당일에 SNS나 전화로 문의주세요.
(070-8811-2222)`,
    ],
    address: `서울특별시 중구 마른내로 72, 인현상가 
세운메이커스큐브(상가외부데크) 중 301호`,
  },
  {
    index: 5,
    name: "김을지로, 도한결(모조산업), 불도저, 오늘의 풍경, 프이치 스튜디오",
    id: StudioName.uljiroEtc,
    schedule: [
      `24일(토) 12:00 - 19:00 (오늘의 풍경 외 모두)`,
      `25일(일) 오늘의 풍경 방문예약`,
    ],
    img: [
      uljiroSrc,
      mojoSrc,
      bdzSrc1,
      bdzSrc2,
      bdzSrc3,
      bdzSrc4,
      todaySrc,
      fychSrc1,
      fychSrc2,
      fychSrc3,
      fychSrc4,
    ],
    imgCaption: [
      "김을지로",
      "도한결(모조산업)",
      "불도저",
      "불도저 판매전",
      "불도저 판매전",
      "불도저 판매전",
      "오늘의 풍경",
      "프이치 스튜디오",
      "프이치 스튜디오",
      "프이치 스튜디오",
      "프이치 스튜디오",
    ],
    member: [
      "김을지로",
      "도한결(모조산업)",
      "불도저",
      "오늘의 풍경",
      "프이치 스튜디오",
    ],
    text: [
      `
검정색과 초록색 그리고 모눈을 좋아합니다.`,
      `
시각/예술 분야의 다양한 작업물을 생산하는 디자인 스튜디오 모조산업의 전방위 디자이너. 을지로를 쏘다니며 이것저것 만듭니다. 스튜디오의 성격상 그래픽으로 시작된 평면의 작업들을 주로 다루며 틈틈이 이를 물성을 가진 오브제로 변환하는 작업을 진행합니다. 이 변환의 과정에서 발견되는 재미를 찾고 그러한 순간들을 더 많이 포착하고자 합니다.

mojoind.kr
mojoindustry`,
      `
그래픽 디자이너 양민영은 을지로에 위치한 작업실에서 일한다. 이곳에서 많은 일들을 하는데, 불도저라는 이름으로 그래픽 디자인 일을 하고, 불도저프레스라는 이름으로 잡지쿨 등 책을 출판한다. 그 외에도 옷정리 등 크고 작은 프로젝트를 기획하기도 하고, 때로는 크고 작은 제품을 만들기도 한다.

➔ 불도저에서 만든 작은 굿즈류와 불도저프레스에서 출판한 잡지쿨을 판매합니다. 방문하신 분께는 선착순으로 일부 굿즈류를 증정합니다.
(일정 수량 소진시 종료)`,
      `
그래픽 디자이너. 돈을 잘 벌고 싶은 반자본주의자. 책은 늘 국내외 가리지 않고 충동구매한다. abebooks에서 책 사다가 비정상적 연속 결제로 카드 정지를 먹은 적 있다. 이번 오픈 스튜디오 때는 요런 책들을 정리해다가 같이 보면 어떨까 생각중~

➔ 오풍 추천 책 구경하기 타임
예약 하시는 분에 한해 오풍 추천 책 구경하기 타임 가지실 수 있어요~! (맨날 사기만 하고 제대로 보질 않아서 ㅋㅋㅋ 저도 볼 겸사겸사~)
(부대행사 예약링크: 상단 일시란 참조)`,
      `그래픽 디자이너 김희애는 일러스트레이션을 기반으로 하는 디자인 전반의 프로젝트를 수행해 왔다.
 
이미지와 텍스트의 관계 설정을 조정해 보며 내러티브를 전개하는 그래픽 노블 시리즈 IRUKA HOTEL의 <bottle nose dolphin hotel>, <yellow colored allergy>, <uneven noon>을 출판했으며, 스토리텔링을 기반으로 비언어적 내용을 시각화하는 것을 위주로 프로젝트를 진행하는 스튜디오인 프이치를 2020년에 설립하였다.

➔ 을지로 3가에 위치한 프이치의 스튜디오에서는 책 <bottle nose dolphin hotel>, <uneven noon>과 포스터 <Word Salad Blue>를 10% 할인 된 가격에 판매하는 이벤트가 열립니다. 프이치 뿐만 아니라 불도저, 오늘의 풍경, 김을지로, 모조산업이 함께 사용하고 있는 스튜디오 공간을 자유롭게 둘러보실 수 있습니다.`,
    ],
    address: `서울특별시 중구 을지로 108, 502호`,
  },
  {
    index: 6,
    name: "김지우, 소마킴, 이혜원",
    id: StudioName.joySomaHanga,
    schedule: [`24일(토) 12:00 - 19:00`, `25일(일) 12:00 - 19:00`],
    img: [
      joySrc1,
      joySrc2,
      joySrc3,
      somaSrc1,
      somaSrc2,
      somaSrc3,
      lhwSrc1,
      lhwSrc2,
      lhwSrc3,
    ],
    imgCaption: [
      "김지우",
      "김지우",
      "김지우",
      "소마킴",
      "소마킴",
      "소마킴",
      "이혜원",
      "이혜원",
      "이혜원",
    ],
    member: ["김지우", "소마킴", "이혜원"],
    text: [
      `
웹개발도 하고 전시도 하고 작당모의도 합니다. 작업실에서는 자주 '...아니, 왜?'를 외칩니다. 오시면 예술얘기, 사랑얘기 나눠요.

zuzokim@gmail.com`,
      `
매일 아침 9시에 작업실에 출근해서 음악과 미디어아트 작업을 합니다. 9 to 6를 꿈꾸지만 매일 실패합니다. 주로 기술매체에서 파생되는 이미지에 대한 관심에서 출발하여 신체감각과 좌표에 대한 이야기를 합니다.

xxomakim@gmail.com
@adjustmentlayer_`,
      `
작업실에서 글을 쓰고 그림을 그립니다. 작업을 집중해서 하는 날도 있고 그냥 놀러 나오는 날도 있습니다. 같이 작업실을 쓰는 친구들이 있는 날엔 뭔가 나도 열심히 해야 할 것 같은 기분에 조금 더 힘을 냅니다. 작업 동력의 원천은 아무래도 같이 작업실을 쓰는 친구들의 감시와 마감인가 봅니다. 이번에도 오픈스튜디오라는 작업 마감의 원천을 사용하여 어떻게든지 작업실을 유용하게 쓰고 있습니다. 계단이 많아서 조금 힘겨운 걸음이겠지만 많이 놀러 오세요~

heawonlee3@gmail.com`,
    ],
    address: `서울특별시 중구 수표로 10길 5-5,
금정빌딩 602호`,
  },
  {
    index: 7,
    name: "김희경(김희빛), 박민영, 유민(민)",
    id: StudioName.kimParkYu,
    schedule: [`24일(토) 12:00 - 19:00`, `25일(일) 12:00 - 19:00`],
    img: [
      khgSrc2,
      khgSrc1,
      khgSrc3,
      khgSrc4,
      khgSrc5,
      khgSrc6,
      pmySrc1,
      pmySrc2,
      pmySrc3,
      pmySrc4,
      pmySrc5,
      pmySrc6,
      uminSrc1,
      uminSrc2,
      uminSrc3,
      uminSrc4,
      uminSrc5,
      uminSrc6,
    ],
    imgCaption: [
      "김희경(김희빛)",
      "김희경(김희빛)",
      "김희경(김희빛)",
      "김희경(김희빛)",
      "김희경(김희빛)",
      "김희경(김희빛)",
      "박민영 1",
      "박민영 2",
      "박민영 3",
      "박민영 4",
      "박민영 5",
      "박민영 6",
      "유민(민)",
      "유민(민)",
      "유민(민)",
      "유민(민)",
      "유민(민)",
      "유민(민)",
    ],
    member: ["김희경(김희빛)", "박민영", "유민(민)"],
    text: [
      `
김희경(김희빛)은 조형구조로서 문제해결에 기여하는 디자인을 내놓는 제안자입니다.

그래픽을 뽑고 판화를 찍어내는 이미지 제작자입니다. 시민들에게 답변으로 돌아오는 “현실적인 어려움”을 희석시키는 디자인을 제안하고, 아이디어를 공유하는 일을 합니다. 이러한 디자인이 결국 제도적, 행정적 어려움을 넘어서 우리 곁에 실제하기 쉽지 않다는 지점이 있기에, 제안자로 남고 있습니다. 제안자로만 남지 않기 위해서, 디자인 프로젝트를 통해 시민연대를 구성할 수 있는 기획에 대해 고민하고 있습니다. 

제안자에서 제작자로 넘어가지 못하는 서러움을 이미지 생산으로 채우고 있습니다. 못생기고 집요한 이미지를 좋아합니다. 못생긴건 새롭습니다. 집요한건 경이롭습니다. 액정 넘어서 결국 우리에게 다다른 광선들이 인간과 어떻게 관계를 맺었는지에 대해 생각합니다. 그리고 저는 그것을 다시 액정 속 광선으로 만들어내고, 다시 액정 밖 사람들에게로 닿게 합니다. 광선의 연장이 보는 이들을 즐겁게 한다면 충분할 꺼 같은 기분이네요.`,
      `
1. 연대를 끊어 놓는 방식으로 연대하는 사람이 되는 것을 경계할 필요가 있다는 말. 이 말은 내가 대학에서 배운 말 중 가장 오랜 기간동안 체득할 말이라고 직감한다. 내 상상 속에서 이미 괘씸해진 젊은 세대 퀴어의 퉁명스런 한 마디에 나는 또 그 말을 떠올렸다. 나는 우리의 미래가 협소해지지 않기를 바라며 가끔씩 너그러운 말과 상황들을 보고 배워야했다.

2-3. 대안이 필요한 누군가에게 ʻ대안ʼ은 ʻ대안+어쩌고ʼ가 아닌 최선이다. 파트너(들)/생활동반자는 언제부턴가 구별 없이 남용되는 말인 ʻ대안적ʼ 가치도 아니며, 구성원이 서로에게 제공 하는 가족 서비스도 아니며, 온전한 시민이 되는 인정욕구를 충족해주는 것에도 그 의미를 두지 않는다. 앞선 수식어들은 그 자체를 해석하는 일부 관점들의 틀이며, 혹은 부수적인 효과일 수 있다. 다만 기존의 제도라는 것 또한 일상화된 관점과 부수적 효과라는 걸 안다면, 파트너(들)/생활동반자와 함께 하는 삶은 이미 일상으로 닥친 일이다.

4-5. 함께 산다는 것은 어떤 니즈(needs)가 내게 입혀지는 일이기도 하다. 때문에 동행은 기대로 부푼 경험일 뿐만 아니라, 빈번히 불안하고 늘 조심스러운 선택이다. 환호나 유희가 아니라. 여기에 ʻ환희ʼ라는 흔한 기쁨의 이름을 가진 아이가 있다. 그의 가족들은 누군지 모를 환희의 동반자에게 영상편지를 남긴다.

6. 해태로프. 해태 + 줄. 선악을 판별하는 동물이자 전통적 장소에 늠름히 앞을 지키는 상징물. 서울시가 귀여운 마스코트로 쓰기도 한다. 귀엽지만 특징이 없게 생겨서 매번 앞다리 보이는 자세로만 확인되는 그 해태. 꼴보기 싫어서 만들었다.`,
      `
민은 그림을 그린다.

서로 떨어진 곳에서 연주되는 오르골 악보,

여러 개의 캔버스 조각에 나뉘어 그려진 한 그루의 나무, 여러 장에 부분 부분 나뉘어 찍힌 한 판의 수신인 불명의 편지…

이 모든 시도는 한계를 부정하지 않으면서 한계를 탈출하는 방법에 대한 탐구이며 시도이다. 

우리는 그림을 봄으로써 그림에 닿는다. 

우리의 움직임이 닿는 곳은 그림 자체이면서 그림 너머에 있는 어느 곳이다. 

우리는 어떻게 그 한계를 넘어 우리가 되는 것일까. 

혹은 이렇게도 질문할 수 있다. 우리는 어떻게 한계를 넘어 너와 내가 되는 것일까? 

민의 그림paint-ing은 이러한 질문들을 던진다.

----------------------------

12:00 - 14:00 지킴이 박민영, 유민(민) / 부재 김희경(김희빛)
14:00 - 16:00 지킴이 김희경(김희빛), 박민영 / 부재 유민(민)
16:00 - 18:00 지킴이 김희경(김희빛), 유민(민) / 부재 박민영
18:00 - 19:00 지킴이 김희경(김희빛), 박민영, 유민(민)
`,
    ],
    address: "서울특별시 중구 퇴계로 275 영수빌딩 202호",
  },
];

