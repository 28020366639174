/** @jsxImportSource @emotion/react */
import { useState, MouseEvent, useRef } from "react";
import { css } from "@emotion/react";
import {
  Stack,
  Typography,
  Dialog,
  dialogClasses,
  backdropClasses,
  DialogTitle,
  DialogContent,
  paperClasses,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import four00viewPng from "../../../assets/images/400view.png";
import bomaPng from "../../../assets/images/boma.png";
import euljiroetcPng from "../../../assets/images/euljiroetc.png";
import listenPng from "../../../assets/images/listen.png";
import relayPng from "../../../assets/images/relay.png";
import joysomahangaPng from "../../../assets/images/joysomahanga.png";
import womanPng from "../../../assets/images/woman.png";
import kimparkyuPng from "../../../assets/images/kimparkyu.png";
import {
  StudioName,
  STUDIO_CONTENTS_LIST,
  StudioColor,
  StudioContent,
} from "./constant";
import { MatterBlock, Slide, MatterFunction } from "../../patterns";

import fourviewSrc from "../../../assets/images/stacks/main_400view.svg";
import bomaSrc from "../../../assets/images/stacks/main_boma.png";
import designers2Src from "../../../assets/images/stacks/main_designers2.png";
import kimleekimSrc from "../../../assets/images/stacks/main_kimleekim.svg";
import listenSrc from "../../../assets/images/stacks/main_listen.svg";
import relaySrc from "../../../assets/images/stacks/main_relay.svg";
import woman2Src from "../../../assets/images/stacks/main_woman2.svg";
import yookimparkSrc from "../../../assets/images/stacks/main_yookimpark.svg";

import studioBlocks from "../../../assets/2023/design-assets/studio_blocks.png";
import {
  SECOND_STUDIOS_CONTENTS,
  SecondStudioContent,
  SecondStudioId,
  StudioBlock,
} from "./second-constant";

export const StudioHeaderPng: Record<StudioName, string> = {
  [StudioName.four00view]: four00viewPng,
  [StudioName.boma]: bomaPng,
  [StudioName.listentotheCity]: listenPng,
  [StudioName.relay]: relayPng,
  [StudioName.womanOpenTechLab]: womanPng,
  [StudioName.uljiroEtc]: euljiroetcPng,
  [StudioName.joySomaHanga]: joysomahangaPng,
  [StudioName.kimParkYu]: kimparkyuPng,
};

export const StudioStackSvg: Record<StudioName, string> = {
  [StudioName.four00view]: fourviewSrc,
  [StudioName.boma]: bomaSrc,
  [StudioName.listentotheCity]: listenSrc,
  [StudioName.relay]: relaySrc,
  [StudioName.womanOpenTechLab]: woman2Src,
  [StudioName.uljiroEtc]: designers2Src,
  [StudioName.joySomaHanga]: kimleekimSrc,
  [StudioName.kimParkYu]: yookimparkSrc,
};

const blockStyle = css``;

interface SecondStudiosProps {}

const SecondStudios = (props: SecondStudiosProps) => {
  const [selectedId, setSelectedId] = useState<StudioName | null>(null);

  const handleClickOpen = (id: string) => {
    setSelectedId(id as StudioName);
  };

  const handleClose = () => {
    setSelectedId(null);
  };

  const BLOCK_SIZE = 170;

  return (
    <>
      <Stack
        css={css`
          align-items: center;
          padding: 8px 24px 28px 24px;
          position: relative;
          isolation: isolate;
          margin: 24px;
          height: 100%;
          @media (min-width: 420px) {
            height: 80vh;
            justify-content: center;
            margin: 0 44px;
          }
          @media (min-width: 720px) {
            margin: 44px;
          }
        `}
      >
        <Stack
          css={css`
            position: relative;
            display: grid;
            grid-template-columns: repeat(3, auto);
            grid-template-rows: repeat(4, auto);
          `}
        >
          {SECOND_STUDIOS_CONTENTS.map((v: SecondStudioContent) => {
            const dummy =
              v.id === SecondStudioId.OpenStudio ||
              v.id === SecondStudioId.OpenStudioTime;
            return (
              <div
                css={blockStyle}
                key={v.id}
                onClick={(e) => {
                  if (
                    v.id === SecondStudioId.OpenStudio ||
                    v.id === SecondStudioId.OpenStudioTime
                  ) {
                    return;
                  } else {
                    handleClickOpen(v.id);
                  }
                }}
              >
                <img
                  src={StudioBlock[v.id as SecondStudioId]}
                  alt={v.name}
                  css={css`
                    width: 120px;
                    @media (min-width: 420px) {
                      width: 160px;
                    }
                    @media (min-width: 720px) {
                      width: 200px;
                    }
                    height: auto;
                    display: block;
                    ${dummy &&
                    css`
                      &:hover {
                        cursor: not-allowed;
                        opacity: 0.8;
                      }
                    `}
                    ${!dummy &&
                    css`
                      &:hover {
                        cursor: pointer;
                        transform: scale(1.04);
                      }
                    `}
                  `}
                />
              </div>
            );
          })}
        </Stack>

        {/* ----다이얼로그 */}
        <Dialog
          open={Boolean(selectedId)}
          onClose={handleClose}
          css={css`
            z-index: 9999;
            & .${paperClasses.root} {
              width: 100%;
              max-width: 100%;
              margin: 18px;
              border-radius: 0;
              border: 1px solid;
              box-shadow: none;
            }
            & .${backdropClasses.root} {
              background-color: #ffffff99;
            }
          `}
        >
          <DialogTitle>
            {selectedId && (
              <CloseIcon
                onClick={handleClose}
                css={css`
                  cursor: pointer;
                  position: absolute;
                  top: 16px;
                  right: 16px;
                `}
              />
            )}
          </DialogTitle>

          <DialogContent
            css={css`
              margin: 16px 0 40px 0;
              padding: 0 40px;
              overflow-y: auto;
              position: relative;
            `}
          >
            <Stack gap="16px">
              <Stack
                css={css`
                  width: 100%;
                  align-items: center;
                `}
              >
                <img
                  css={css`
                    aspect-ratio: 1/1;
                    width: 80px;
                    @media (min-width: 420px) {
                      width: 120px;
                    }
                  `}
                  src={StudioBlock[selectedId as unknown as SecondStudioId]}
                  alt={selectedId ?? "studio"}
                />
              </Stack>

              <Stack>
                <Slide
                  imgs={
                    SECOND_STUDIOS_CONTENTS.find((v) => v.id === selectedId)
                      ?.img
                  }
                  imgCaptions={
                    SECOND_STUDIOS_CONTENTS.find((v) => v.id === selectedId)
                      ?.imgCaption
                  }
                />
              </Stack>

              <Stack
                css={css`
                  margin: 0 0 40px 0;
                  align-items: center;
                `}
              >
                <Stack css={css``}>
                  <Stack
                    css={css`
                      flex-direction: row;
                      gap: 4px;
                    `}
                  >
                    <Typography
                      css={css`
                        font-size: 12px;
                        white-space: nowrap;
                        @media (min-width: 720px) {
                          font-size: 14px;
                        }
                      `}
                    >
                      <b>주소</b>
                    </Typography>

                    <Typography
                      css={css`
                        white-space: pre-wrap;
                        font-size: 12px;
                        @media (min-width: 720px) {
                          font-size: 14px;
                        }
                      `}
                    >
                      {
                        SECOND_STUDIOS_CONTENTS.find((v) => v.id === selectedId)
                          ?.address
                      }
                    </Typography>
                  </Stack>
                  <Stack
                    css={css`
                      flex-direction: row;
                      gap: 4px;
                    `}
                  >
                    <Typography
                      css={css`
                        font-size: 12px;
                        white-space: nowrap;
                        @media (min-width: 720px) {
                          font-size: 14px;
                        }
                      `}
                    >
                      <b>일시 </b>
                    </Typography>
                    <Stack>
                      {SECOND_STUDIOS_CONTENTS.find(
                        (v) => v.id === selectedId
                      )?.schedules.map((schedule, i) => {
                        return (
                          <Typography
                            key={i}
                            css={css`
                              font-size: 12px;
                              @media (min-width: 720px) {
                                font-size: 14px;
                              }
                            `}
                          >
                            {schedule}
                          </Typography>
                        );
                      })}
                    </Stack>
                  </Stack>
                  {SECOND_STUDIOS_CONTENTS.find((v) => v.id === selectedId)
                    ?.bookingLink && (
                    <Stack
                      css={css`
                        flex-direction: row;
                        gap: 4px;
                      `}
                    >
                      <Typography
                        css={css`
                          font-size: 12px;
                          white-space: nowrap;
                          @media (min-width: 720px) {
                            font-size: 14px;
                          }
                        `}
                      >
                        <b>예약</b>
                      </Typography>

                      <Typography
                        onClick={() => {
                          window.location.replace(
                            SECOND_STUDIOS_CONTENTS.find(
                              (v) => v.id === selectedId
                            )?.bookingLink ?? ""
                          );
                        }}
                        css={css`
                          color: blue;
                          text-decoration: underline;
                          white-space: pre-wrap;
                          font-size: 12px;
                          &:hover {
                            cursor: pointer;
                          }
                          @media (min-width: 720px) {
                            font-size: 14px;
                          }
                        `}
                      >
                        {
                          SECOND_STUDIOS_CONTENTS.find(
                            (v) => v.id === selectedId
                          )?.bookingLink
                        }
                      </Typography>
                    </Stack>
                  )}
                </Stack>
                <Stack
                  css={css`
                    margin-top: 32px;
                    @media (min-width: 720px) {
                      margin-top: 24px;
                      width: 500px;
                    }
                  `}
                >
                  {SECOND_STUDIOS_CONTENTS.find(
                    (v) => v.id === selectedId
                  )?.member.map((name, i) => (
                    <Stack gap="4px" marginBottom="16px">
                      <Typography
                        css={css`
                          white-space: pre-wrap;
                          text-align: justify;
                          /* word-break: keep-all; */

                          font-size: 12px;
                          @media (min-width: 720px) {
                            font-size: 16px;
                          }
                        `}
                      >
                        <b
                          css={css`
                            white-space: pre-wrap;
                          `}
                        >
                          {name}
                        </b>
                      </Typography>
                      <Typography
                        css={css`
                          white-space: pre-wrap;
                          text-align: justify;
                          word-break: break-all;

                          font-size: 12px;
                          @media (min-width: 720px) {
                            font-size: 14px;
                          }
                        `}
                      >
                        {
                          SECOND_STUDIOS_CONTENTS.find(
                            (v) => v.id === selectedId
                          )?.text[i]
                        }
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </Stack>
    </>
  );
};

export default SecondStudios;
