/** @jsxImportSource @emotion/react */
import { useState, MouseEvent, useRef } from "react";
import { css } from "@emotion/react";
import {
  Stack,
  Typography,
  Dialog,
  dialogClasses,
  backdropClasses,
  DialogTitle,
  DialogContent,
  paperClasses,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import four00viewPng from "../../../assets/images/400view.png";
import bomaPng from "../../../assets/images/boma.png";
import euljiroetcPng from "../../../assets/images/euljiroetc.png";
import listenPng from "../../../assets/images/listen.png";
import relayPng from "../../../assets/images/relay.png";
import joysomahangaPng from "../../../assets/images/joysomahanga.png";
import womanPng from "../../../assets/images/woman.png";
import kimparkyuPng from "../../../assets/images/kimparkyu.png";
import { StudioName, STUDIO_CONTENTS_LIST, StudioColor } from "./constant";
import { MatterBlock, Slide, MatterFunction } from "../../patterns";

import fourviewSrc from "../../../assets/images/stacks/main_400view.svg";
import bomaSrc from "../../../assets/images/stacks/main_boma.png";
import designers2Src from "../../../assets/images/stacks/main_designers2.png";
import kimleekimSrc from "../../../assets/images/stacks/main_kimleekim.svg";
import listenSrc from "../../../assets/images/stacks/main_listen.svg";
import relaySrc from "../../../assets/images/stacks/main_relay.svg";
import woman2Src from "../../../assets/images/stacks/main_woman2.svg";
import yookimparkSrc from "../../../assets/images/stacks/main_yookimpark.svg";

export const StudioHeaderPng: Record<StudioName, string> = {
  [StudioName.four00view]: four00viewPng,
  [StudioName.boma]: bomaPng,
  [StudioName.listentotheCity]: listenPng,
  [StudioName.relay]: relayPng,
  [StudioName.womanOpenTechLab]: womanPng,
  [StudioName.uljiroEtc]: euljiroetcPng,
  [StudioName.joySomaHanga]: joysomahangaPng,
  [StudioName.kimParkYu]: kimparkyuPng,
};

export const StudioStackSvg: Record<StudioName, string> = {
  [StudioName.four00view]: fourviewSrc,
  [StudioName.boma]: bomaSrc,
  [StudioName.listentotheCity]: listenSrc,
  [StudioName.relay]: relaySrc,
  [StudioName.womanOpenTechLab]: woman2Src,
  [StudioName.uljiroEtc]: designers2Src,
  [StudioName.joySomaHanga]: kimleekimSrc,
  [StudioName.kimParkYu]: yookimparkSrc,
};

interface StudiosProps {}

const Studios = (props: StudiosProps) => {
  const [selectedId, setSelectedId] = useState<StudioName | null>(null);

  const handleClickOpen = (id: string) => {
    setSelectedId(id as StudioName);
  };

  const handleClose = () => {
    setSelectedId(null);
  };

  return (
    <Stack
      css={css`
        align-items: center;
        padding: 16px 24px;
      `}
    >
      {STUDIO_CONTENTS_LIST.map((v, i) => {
        return (
          // <div key={i}> -</div>
          <div
            id={v.id as StudioName}
            onClick={(e) => handleClickOpen(e.currentTarget.id)}
            key={i}
            css={css`
              /* width: 100%; */
              /* border: 1px solid #a5a3a3;
              border-bottom: none; */
              display: flex;
              flex-direction: column;
              padding: 8px;
              /* aspect-ratio: 1/1; */
              cursor: pointer;
              width: 99%;
              height: auto;
              @media (min-width: 720px) {
                width: 550px;
                height: auto;
              }
              @media (min-width: 1440px) {
                width: 700px;
                height: auto;
              }
            `}
          >
            <img
              src={StudioStackSvg[v.id as StudioName]}
              alt={v.name}
              css={css`
                width: 100%;
                height: auto;
              `}
            />
          </div>
        );
        // return (
        //   <Typography
        //     key={i}
        //     id={v.id as StudioName}
        //     onClick={(e) => handleClickOpen(e.currentTarget.id)}
        //     css={css`
        //       cursor: pointer;
        //     `}
        //   >
        //     {v.id}
        //   </Typography>
        // );
      })}

      {/* <MatterBlock /> */}
      {/* <MatterFunction /> */}

      {/* ----다이얼로그 */}
      <Dialog
        open={Boolean(selectedId)}
        onClose={handleClose}
        css={css`
          /**title-header's z-index: 10000 */
          z-index: 20000;
          & .${paperClasses.root} {
            width: 85%;
            max-width: 85%;
            margin: 16px;
            border-radius: 0;
            border: 2px solid
              ${StudioColor[selectedId ?? ("#fff" as unknown as StudioName)]};
            box-shadow: none;
          }
          & .${backdropClasses.root} {
            background-color: #ffffff99;
          }
        `}
      >
        <DialogTitle>
          {selectedId && (
            <CloseIcon
              onClick={handleClose}
              css={css`
                cursor: pointer;
                position: absolute;
                top: 16px;
                right: 16px;
              `}
            />
          )}
        </DialogTitle>

        <DialogContent
          css={css`
            margin: 16px 0 40px 0;
            padding: 0 40px;
            overflow-y: auto;
            position: relative;
          `}
        >
          <Stack gap="16px">
            <Stack
              css={css`
                width: 100%;
                align-items: center;
              `}
            >
              <img
                css={css`
                  /* aspect-ratio: 1/1; */
                  width: 100%;
                  height: auto;
                  /* border: 1px solid red; */
                  @media (min-width: 360px) {
                  }
                  @media (min-width: 720px) {
                    width: 500px;
                  }
                  @media (min-width: 1440px) {
                    width: 800px;
                  }
                `}
                src={
                  StudioHeaderPng[selectedId ?? ("" as unknown as StudioName)]
                }
                alt={selectedId ?? ""}
              />
            </Stack>
            <Stack>
              <Slide
                imgs={
                  STUDIO_CONTENTS_LIST.find((v) => v.id === selectedId)?.img
                }
                imgCaptions={
                  STUDIO_CONTENTS_LIST.find((v) => v.id === selectedId)
                    ?.imgCaption
                }
              />
            </Stack>

            <Stack
              css={css`
                margin: 0 0 40px 0;
                /* min-height: 80vh; */
                align-items: center;
              `}
            >
              <Stack css={css``}>
                <Stack
                  css={css`
                    flex-direction: row;
                    gap: 4px;
                  `}
                >
                  <Typography
                    css={css`
                      font-size: 12px;
                      white-space: nowrap;
                      @media (min-width: 720px) {
                        font-size: 16px;
                      }
                    `}
                  >
                    <b>주소</b>
                  </Typography>

                  <Typography
                    css={css`
                      white-space: pre-wrap;
                      font-size: 12px;
                      @media (min-width: 720px) {
                        font-size: 16px;
                      }
                    `}
                  >
                    -
                  </Typography>
                </Stack>
                <Stack
                  css={css`
                    flex-direction: row;
                    gap: 4px;
                  `}
                >
                  <Typography
                    css={css`
                      font-size: 12px;
                      white-space: nowrap;
                      @media (min-width: 720px) {
                        font-size: 16px;
                      }
                    `}
                  >
                    <b>일시 </b>
                  </Typography>
                  <Stack>
                    {STUDIO_CONTENTS_LIST.find(
                      (v) => v.id === selectedId
                    )?.schedule.map((sch, i) => {
                      return (
                        <Typography
                          key={i}
                          css={css`
                            font-size: 12px;
                            @media (min-width: 720px) {
                              font-size: 16px;
                            }
                          `}
                        >
                          {sch}
                        </Typography>
                      );
                    })}
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                css={css`
                  margin-top: 32px;
                  @media (min-width: 720px) {
                    margin-top: 24px;
                    width: 500px;
                  }
                `}
              >
                {STUDIO_CONTENTS_LIST.find(
                  (v) => v.id === selectedId
                )?.member.map((name, i) => (
                  <Stack gap="4px" marginBottom="16px">
                    <Typography
                      css={css`
                        white-space: pre-wrap;
                        text-align: justify;
                        /* word-break: keep-all; */

                        font-size: 12px;
                        @media (min-width: 720px) {
                          font-size: 16px;
                        }
                      `}
                    >
                      <b
                        css={css`
                          white-space: pre-wrap;
                        `}
                      >
                        {name}
                      </b>
                    </Typography>
                    <Typography
                      css={css`
                        white-space: pre-wrap;
                        text-align: justify;
                        /* word-break: keep-all; */

                        font-size: 12px;
                        @media (min-width: 720px) {
                          font-size: 16px;
                        }
                      `}
                    >
                      {
                        STUDIO_CONTENTS_LIST.find((v) => v.id === selectedId)
                          ?.text[i]
                      }
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default Studios;
