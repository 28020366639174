/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import HeaderImgSrc from "../../assets/2023/design-assets/studio_logo.png";

const navContainerStyle = css`
  /* background-color: rgba(255, 255, 255, 0.9);
  flex-direction: row;
  padding: 16px 8px;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10000;
  @media (min-width: 1920px) {
    padding: 16px 48px;
  } */
`;

const Header = () => {
  return (
    <Link
      to="/"
      style={{ textDecoration: "none", color: "#000", display: "flex" }}
    >
      <img
        src={HeaderImgSrc}
        alt="open_studio_header"
        css={css`
          width: 56px;
          &:hover{
            transform: scale(1.03);
          }
        `}
      />
    </Link>
  );
};

export default Header;
