/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { StudioName, StudioLocation } from "../Studios/constant";
import {
  STUDIO_LOCATION_LIST,
  SecondStudioId,
} from "../Studios/second-constant";

const DISGUISED_LOCATION = {
  latitude: 37.56650756586369,
  longtitude: 126.98972231100434,
};

interface SecondMapProps {}

const SecondMap = (props: SecondMapProps) => {
  const mapElement = useRef(null);
  const theme = useTheme();
  const muiMdUp = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    const { naver } = window;
    if (!mapElement.current || !naver) return;

    const location = new naver.maps.LatLng(
      //임시 임의중앙값
      37.5465578,
      126.9800726
    );
    const mapOptions = {
      center: location,
      zoom: muiMdUp ? 13 : 12,
      zoomControl: true,
      zoomControlOptions: {
        style: naver.maps.ZoomControlStyle.SMALL,
        position: naver.maps.Position.TOP_RIGHT,
      },
    };
    const map = new naver.maps.Map(mapElement.current, mapOptions);

    STUDIO_LOCATION_LIST.forEach((studio, i) => {
      const studioMarker = new naver.maps.Marker({
        position: new naver.maps.LatLng(studio.latitude, studio.longtitude),
        map,
        title: studio.name,
        icon: {
          url: studio.src,
        },
      });

      const link = `${studio.link}`;

      const InfoWindow = new naver.maps.InfoWindow({
        content: [
          "<div style='display:flex; flex-direction: column; align-items: center; padding: 0 8px'>",
          `<p style='margin: 4px 0 0 0;'><b>${i + 1} ${studio.name}</b></p>`,
          "<p style='margin: 4px; font-size:12px;'>",
          `<p style="color: #000; margin: 0; padding-bottom: 4px; ">${studio.address}</p>`,
          "</div>",
        ].join(""),
      });

      const InfoWindowListener = naver.maps.Event.addListener(
        studioMarker,
        "click",
        () => {
          if (InfoWindow.getMap()) {
            InfoWindow.close();
          } else {
            InfoWindow.open(map, studioMarker);
          }
        }
      );
    });
  }, [muiMdUp]);

  return (
    <Stack alignItems="center" position="relative">
      <Stack alignItems="center" justifyContent="center" width="100%">
        <div
          ref={mapElement}
          css={css`
            width: 100%;
            height: 80vh;
            &:focus-visible {
              outline: none;
            }
          `}
        />
      </Stack>
      <Stack
        css={css`
          position: absolute;
          bottom: -34px;
          left: 10px;
          transform: translateY(-25%);
          z-index: 999999;
          width: fit-content;
          padding: 8px;
          background-color: white;
          border: 1.3px solid;
          display: flex;
          flex-direction: column;
          gap: 4px;
          font-size: 10px;
          @media (min-width: 720px) {
            font-size: 12px;
          }
        `}
      >
        {STUDIO_LOCATION_LIST.map((v, i) => (
          <div key={i}>
            <b>{i + 1} </b>
            {v.name}
          </div>
        ))}
      </Stack>
    </Stack>
  );
};

export default SecondMap;
