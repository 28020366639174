/** @jsxImportSource @emotion/react */
import { useState, MouseEvent, useEffect } from "react";
import { css } from "@emotion/react";
import {
  Stack,
  Typography,
  Menu,
  MenuItem,
  menuClasses,
  paperClasses,
} from "@mui/material";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Link, useLocation } from "react-router-dom";
import Header from "./Header";

const navContainerStyle = css`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 8px 0;
  border-bottom: 1px solid #00000060;
  z-index: 999;
`;

const navButtonStyle = css`
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.03rem;
  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }
  @media (min-width: 1920px) {
    font-size: 20px;
  }
`;

const menuStyle = css`
  margin-top: 8px;
  & .${menuClasses.list} {
    padding-top: 0;
    padding-bottom: 0;
  }
  & .${paperClasses.root} {
    border: 1px solid #000;
    border-radius: 0;
  }
`;

const menuItemStyle = css`
  justify-content: center;
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: fit-content;
`;

const NavigationBar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { pathname } = useLocation();
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleClose();
  }, [pathname]);

  return (
    <>
      <Stack css={navContainerStyle}>
        <Header />
        <Stack direction="row" css={navButtonStyle} onClick={handleClick}>
          <Link to="/studio" style={{ textDecoration: "none", color: "#000" }}>
            <Typography
              css={css`
                font-size: 14px;
                @media (min-width: 1920px) {
                  font-size: 20px;
                }
                &:hover {
                  transform: scale(1.03);
                }
              `}
            >
              STUDIO
            </Typography>
          </Link>
          {/* <ArrowDropDownIcon /> */}
        </Stack>
        <Link to="/map" style={{ textDecoration: "none", color: "#000" }}>
          <Typography css={navButtonStyle}>MAP</Typography>
        </Link>
      </Stack>
      {/* <Menu
        id="studio-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        css={menuStyle}
        elevation={0}
      >
        <MenuItem css={menuItemStyle} onClick={handleClose}>
          <Link to="/studios" style={{ textDecoration: "none", color: "#000" }}>
            전체 스튜디오 보기
          </Link>
        </MenuItem>
        <MenuItem css={menuItemStyle} onClick={handleClose}>
          <Link to="/studios" style={{ textDecoration: "none", color: "#000" }}>
            지금 방문 가능한 스튜디오
          </Link>
          보기
        </MenuItem>
      </Menu> */}
    </>
  );
};

export default NavigationBar;
