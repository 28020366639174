/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Stack, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

interface SlideProps {
  imgs?: string[];
  imgCaptions?: string[];
}

const Slide = (props: SlideProps) => {
  const { imgs, imgCaptions } = props;
  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      modules={[Pagination]}
      className="mySwiper"
      css={css`
        cursor: grab;
        aspect-ratio: 1/1;

        width: 100%;
        overflow-x: hidden;
        /* border: 1px solid #000; */
        position: relative;
        @media (min-width: 720px) {
          height: 550px;
          width: 550px;
        }
        @media (min-width: 1440px) {
          height: 700px;
          width: 700px;
        }
        .swiper-pagination-bullet {
          background-color: #000 !important;
        }
        .swiper-pagination-bullet-active {
          background-color: #eee !important;
        }
        .swiper-slide {
          text-align: center;
        }
      `}
    >
      {imgs?.map((v, i) => (
        <SwiperSlide key={i}>
          <img
            src={v}
            alt=" Listen to the City"
            css={css`
              aspect-ratio: 1/1;
              width: 99%;
              @media (min-width: 720px) {
                height: 550px;
                width: 550px;
              }
              @media (min-width: 1440px) {
                height: 700px;
                width: 700px;
              }
            `}
          />
          <Typography
            css={css`
              color: #b3b2b2;
              font-size: 12px;
              position: absolute;
              bottom: 3%;
              left: 50%;
              transform: translate(-50%, -50%);
            `}
          >
            {imgCaptions?.[i]}
          </Typography>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slide;
