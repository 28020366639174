/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { css } from "@emotion/react";

import {
  Header,
  NavigationBar,
  Footer,
  MatterFunction,
} from "./components/patterns";
import { Intro, Map, NotFound, Studios } from "./components/screens";
import { Stack } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SecondStudios from "./components/screens/Studios/SecondStudios";
import SecondMap from "./components/screens/Map/SecondMap";

const App = () => {
  return (
    <Stack>
      <BrowserRouter>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Intro />}></Route>
          <Route path="/studio" element={<SecondStudios />}></Route>
          <Route path="/map" element={<SecondMap />}></Route>
          {/* <Route path="/studios" element={<MatterFunction />}></Route> */}
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </Stack>
  );
};

export default App;
