/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Stack } from "@mui/material";

const linkStyle = css`
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-top: 12px;
  &:link {
    color: #6f6f6f;
    text-decoration: none;
  }
  &:visited {
    color: #6f6f6f;
    text-decoration: none;
  }
  &:hover {
    color: #fe0000;
    text-decoration: none;
  }
`;

const rootStyle = css`
  align-items: center;
  justify-content: center;
  height: 120px;
  border-top: 1px solid #00000060;
  /* margin-top: 24px; */
`;

const Footer = () => {
  return (
    <Stack css={rootStyle}>
      <a
        href={`https://www.instagram.com/openstudio.info`}
        css={linkStyle}
        target="_blank"
      >
        All rights reserved. OpenStudio_2023
      </a>
    </Stack>
  );
};

export default Footer;
