/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Stack } from "@mui/material";

const linkStyle = css`
  display: flex;
  align-items: center;
  font-size: 12px;
  &:link {
    color: #6f6f6f;
    text-decoration: none;
  }
  &:visited {
    color: #6f6f6f;
    text-decoration: none;
  }
  &:hover {
    color: #00f932;
    text-decoration: none;
  }
`;

const NotFound = () => {
  return (
    <Stack alignItems="center" justifyContent="center" height="90vh">
      Not Found
    </Stack>
  );
};

export default NotFound;
