/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { setupNaverMap } from "../../../setUpNaverMap";
import { StudioName, StudioLocation } from "../Studios/constant";
// import four00ViewSrc from "../../../../public/marker_400view.png";

const DISGUISED_LOCATION = {
  latitude: 37.56650756586369,
  longtitude: 126.98972231100434,
};

export const STUDIO_LOCATION_LIST: StudioLocation[] = [
  {
    name: "400VIEW",
    id: StudioName.four00view,
    // latitude: 37.5616855,
    // logtitude: 126.9941057,
    ...DISGUISED_LOCATION,
  },
  {
    name: `boma pak studio(fldjf studio, WTM decoration & boma, Matter et cetera)`,
    id: StudioName.boma,
    // latitude: 37.5663878,
    // logtitude: 126.9893336,
    latitude: DISGUISED_LOCATION.latitude,
    longtitude: DISGUISED_LOCATION.longtitude + 0.001,
  },
  {
    name: "리슨투더시티(Listen to the City)",
    id: StudioName.listentotheCity,
    // latitude: 37.5677719,
    // logtitude: 126.9907129,
    latitude: DISGUISED_LOCATION.latitude,
    longtitude: DISGUISED_LOCATION.longtitude + 0.002,
  },
  {
    name: "릴레이",
    id: StudioName.relay,
    // latitude: 37.5649217,
    // longtitude: 126.9932167,
    latitude: DISGUISED_LOCATION.latitude,
    longtitude: DISGUISED_LOCATION.longtitude + 0.003,
  },
  {
    name: `여성을 위한 열린 기술랩(WOMAN OPEN TECH LAB)`,
    id: StudioName.womanOpenTechLab,
    // latitude: 37.5634956,
    // longtitude: 126.9957894,
    latitude: DISGUISED_LOCATION.latitude,
    longtitude: DISGUISED_LOCATION.longtitude + 0.004,
  },
  {
    name: "김을지로, 도한결(모조산업), 불도저, 오늘의 풍경, 프이치 스튜디오 ",
    id: StudioName.uljiroEtc,
    // latitude: 37.5660144,
    // longtitude: 126.9899261,
    latitude: DISGUISED_LOCATION.latitude,
    longtitude: DISGUISED_LOCATION.longtitude + 0.005,
  },
  {
    name: "김지우, 소마킴, 이혜원",
    id: StudioName.joySomaHanga,
    // latitude: 37.5638008,
    // longtitude: 126.9905831,
    latitude: DISGUISED_LOCATION.latitude,
    longtitude: DISGUISED_LOCATION.longtitude + 0.006,
  },
  {
    name: "김희경(김희빛), 박민영, 유민(민)",
    id: StudioName.kimParkYu,
    // latitude: 37.5633239,
    // longtitude: 127.0020002,
    latitude: DISGUISED_LOCATION.latitude,
    longtitude: DISGUISED_LOCATION.longtitude + 0.007,
  },
];

interface MapProps {}

const Map = (props: MapProps) => {
  const mapElement = useRef(null);
  const theme = useTheme();
  const muiMdUp = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    const { naver } = window;
    if (!mapElement.current || !naver) return;

    // STUDIO_LIST.map((studio, i) => {});

    const location = new naver.maps.LatLng(
      //임시 중앙값
      // 37.565141233824285,
      // 126.99707125991866
      STUDIO_LOCATION_LIST[4].latitude,
      STUDIO_LOCATION_LIST[4].longtitude
    );
    const mapOptions = {
      center: location,
      zoom: 16,
      zoomControl: true,
      zoomControlOptions: {
        style: naver.maps.ZoomControlStyle.SMALL,
        position: naver.maps.Position.TOP_RIGHT,
      },
    };
    const map = new naver.maps.Map(mapElement.current, mapOptions);

    const four00view = new naver.maps.Marker({
      position: new naver.maps.LatLng(
        STUDIO_LOCATION_LIST[0].latitude,
        STUDIO_LOCATION_LIST[0].longtitude
      ),
      map,
      title: "400view",
      icon: {
        url: "/marker_400view.png",
      },
    });

    const four00viewInfowindow = new naver.maps.InfoWindow({
      content: [
        "<div style='display:flex; flex-direction: column; align-items: center; color: #c69c6d;'>",
        "<p style='margin: 4px;'><b>400VIEW</b></p>",
        "<p style='margin: 4px; font-size:12px;'>",
        // '<a href="https://naver.me/GUDxLy4P" target="_blank" style="color: #000;">서울특별시 중구 퇴계로 39길 7 윤미빌딩 203호</a>',
        "</p>",
        "</div>",
      ].join(""),
    });

    const four00viewInfowindowListener = naver.maps.Event.addListener(
      four00view,
      "click",
      () => {
        if (four00viewInfowindow.getMap()) {
          four00viewInfowindow.close();
        } else {
          four00viewInfowindow.open(map, four00view);
        }
      }
    );

    const boma = new naver.maps.Marker({
      position: new naver.maps.LatLng(
        STUDIO_LOCATION_LIST[1].latitude,
        STUDIO_LOCATION_LIST[1].longtitude
      ),
      map,
      icon: {
        url: "/marker_boma.png",
      },
    });

    const bomaInfowindow = new naver.maps.InfoWindow({
      content: [
        "<div style='display:flex; flex-direction: column; align-items: center; color: #ff00ff;'>",
        "<p style='margin: 4px; text-align: center;'><b>boma pak studio<br/>(fldjf studio, WTM decoration & boma,<br />Matter et cetera)</b></p>",
        "<p style='margin: 4px; font-size:12px;'>",
        // '<a href="https://naver.me/xgT9GcEn" target="_blank" style="color: #000; margin-top: -10px;">서울특별시 중구 을지로 103, 602호 (옥탑)</a>',
        "</p>",
        "</div>",
      ].join(""),
    });

    const bomaInfowindowListener = naver.maps.Event.addListener(
      boma,
      "click",
      () => {
        if (bomaInfowindow.getMap()) {
          bomaInfowindow.close();
        } else {
          bomaInfowindow.open(map, boma);
        }
      }
    );

    const listentothecity = new naver.maps.Marker({
      position: new naver.maps.LatLng(
        STUDIO_LOCATION_LIST[2].latitude,
        STUDIO_LOCATION_LIST[2].longtitude
      ),
      map,
      icon: {
        url: "/marker_listentothecity.png",
      },
    });

    const listentothecityInfowindow = new naver.maps.InfoWindow({
      content: [
        "<div style='display:flex; flex-direction: column; align-items: center; color: #1bb7ed;'>",
        "<p style='margin: 4px;'><b>Listen to the City(리슨투더시티)</b></p>",
        "<p style='margin: 4px; font-size:12px;'>",
        // '<a href="https://naver.me/xbnvLLDo" target="_blank" style="color: #000; margin-top: -10px;">서울특별시 중구 수표동 11-2 4층</a>',
        "</p>",
        "</div>",
      ].join(""),
    });

    const listentothecityInfowindowListener = naver.maps.Event.addListener(
      listentothecity,
      "click",
      () => {
        if (listentothecityInfowindow.getMap()) {
          listentothecityInfowindow.close();
        } else {
          listentothecityInfowindow.open(map, listentothecity);
        }
      }
    );

    const relay = new naver.maps.Marker({
      position: new naver.maps.LatLng(
        STUDIO_LOCATION_LIST[3].latitude,
        STUDIO_LOCATION_LIST[3].longtitude
      ),
      map,
      icon: {
        url: "/marker_relay.png",
      },
    });

    const relayInfowindow = new naver.maps.InfoWindow({
      content: [
        "<div style='display:flex; flex-direction: column; align-items: center; color: #ff3c00;'>",
        "<p style='margin: 4px;'><b>릴레이</b></p>",
        "<p style='margin: 4px; font-size:12px;'>",
        // '<a href="https://docs.google.com/forms/d/e/1FAIpQLSc0SvDg6mE27dOfUNVqONdhzMnYSnsTzOx-UTRQ4vO2YttAvA/viewform" target="_blank" style="color: #000; margin-top: -10px;">릴레이 예약 방문 링크</a>',
        "</p>",
        "</div>",
      ].join(""),
    });

    const relayInfowindowListener = naver.maps.Event.addListener(
      relay,
      "click",
      () => {
        if (relayInfowindow.getMap()) {
          relayInfowindow.close();
        } else {
          relayInfowindow.open(map, relay);
        }
      }
    );

    const womantechlab = new naver.maps.Marker({
      position: new naver.maps.LatLng(
        STUDIO_LOCATION_LIST[4].latitude,
        STUDIO_LOCATION_LIST[4].longtitude
      ),
      map,
      icon: {
        url: "/marker_womantechlab.png",
      },
    });

    const womantechlabInfowindow = new naver.maps.InfoWindow({
      content: [
        "<div style='display:flex; flex-direction: column; align-items: center; color: #17bc69;'>",
        "<p style='margin: 4px; text-align: center;'><b>WOMAN OPEN TECH LAB<br />(여성을 위한 열린 기술랩)</b></p>",
        "<p style='margin: 4px; font-size:12px;text-align: center;'>",
        // '<a href="https://naver.me/FLKXDyft" target="_blank" style="color: #000; margin-top: -10px; ">서울특별시 중구 마른내로 72,<br />인현상가 세운메이커스큐브(상가외부데크) 중 301호</a>',
        "</p>",
        "</div>",
      ].join(""),
    });

    const womantechlabInfowindowListener = naver.maps.Event.addListener(
      womantechlab,
      "click",
      () => {
        if (womantechlabInfowindow.getMap()) {
          womantechlabInfowindow.close();
        } else {
          womantechlabInfowindow.open(map, womantechlab);
        }
      }
    );

    const euljiroetc = new naver.maps.Marker({
      position: new naver.maps.LatLng(
        STUDIO_LOCATION_LIST[5].latitude,
        STUDIO_LOCATION_LIST[5].longtitude
      ),
      map,
      icon: {
        url: "/marker_euljiroetc.png",
        content:
          "<div style='display: flex; flex-direction: column; align-items: center; background-color: #808080; width: 20px; height: 20px; border-radius: 30px; '><div>",
      },
    });

    const euljiroetcInfowindow = new naver.maps.InfoWindow({
      content: [
        "<div style='display:flex; flex-direction: column; align-items: center; color: #808080;'>",
        "<p style='margin: 4px; text-align: center;' ><b>김을지로/도한결(모조산업)/불도저/<br />오늘의 풍경/프이치 스튜디오</b></p>",
        "<p style='margin: 4px; font-size:12px;'>",
        // '<a href="https://naver.me/I5cZoNez" target="_blank" style="color: #000; margin-top: -10px;">서울특별시 중구 을지로 108, 502호</a>',
        // '<a href="https://docs.google.com/forms/d/e/1FAIpQLSfUqcqlnLn9CcXVL-sZXCbNrmU4opsghSSNixWBXRtlpIWuVQ/viewform" target="_blank" style="color: #000; display: block; margin-top: 4px;">오풍 추천 책 구경하기 타임 예약링크</a>',
        "</p>",
        "</div>",
      ].join(""),
    });

    const euljiroetcInfowindowListener = naver.maps.Event.addListener(
      euljiroetc,
      "click",
      () => {
        if (euljiroetcInfowindow.getMap()) {
          euljiroetcInfowindow.close();
        } else {
          euljiroetcInfowindow.open(map, euljiroetc);
        }
      }
    );

    const ourstudio = new naver.maps.Marker({
      position: new naver.maps.LatLng(
        STUDIO_LOCATION_LIST[6].latitude,
        STUDIO_LOCATION_LIST[6].longtitude
      ),
      map,
      icon: {
        url: "/marker_ourstudio.png",
      },
    });

    const ourstudioInfowindow = new naver.maps.InfoWindow({
      content: [
        "<div style='display:flex; flex-direction: column; align-items: center; color: #0000ff;'>",
        "<p style='margin: 4px;'><b>김지우/소마킴/이혜원</b></p>",
        "<p style='margin: 4px; font-size:12px;'>",
        // '<a href="https://naver.me/GMvDqyCG" target="_blank" style="color: #000; margin-top: -10px;">서울특별시 중구 수표로 10길 5-5, 금정빌딩 602호</a>',
        "</p>",
        "</div>",
      ].join(""),
    });

    const ourstudioInfowindowListener = naver.maps.Event.addListener(
      ourstudio,
      "click",
      () => {
        if (ourstudioInfowindow.getMap()) {
          ourstudioInfowindow.close();
        } else {
          ourstudioInfowindow.open(map, ourstudio);
        }
      }
    );

    const kimparkyu = new naver.maps.Marker({
      position: new naver.maps.LatLng(
        STUDIO_LOCATION_LIST[7].latitude,
        STUDIO_LOCATION_LIST[7].longtitude
      ),
      map,
      icon: {
        url: "/marker_kimparkyu.png",
      },
    });

    const kimparkyuInfowindow = new naver.maps.InfoWindow({
      content: [
        "<div style='display:flex; flex-direction: column; align-items: center; color: #7c29ff;'>",
        "<p style='margin: 4px;'><b>김희경(김희빛)/박민영/유민(민)</b></p>",
        "<p style='margin: 4px; font-size:12px;'>",
        // '<a href="https://naver.me/5kXZqtS6" target="_blank" style="color: #000; margin-top: -10px;">서울특별시 중구 퇴계로 275 영수빌딩 202호</a>',
        "</p>",
        "</div>",
      ].join(""),
    });

    const kimparkyuInfowindowListener = naver.maps.Event.addListener(
      kimparkyu,
      "click",
      () => {
        if (kimparkyuInfowindow.getMap()) {
          kimparkyuInfowindow.close();
        } else {
          kimparkyuInfowindow.open(map, kimparkyu);
        }
      }
    );
  }, [muiMdUp]);

  return (
    <Stack alignItems="center">
      <Stack
        alignItems="center"
        justifyContent="center"
        paddingTop="8px"
        // paddingBottom="100px"
        width="100%"
      >
        <div
          ref={mapElement}
          css={css`
            width: 85%;
            margin: 50px 0 80px 0;
            aspect-ratio: 4 / 4;
            @media (min-width: 720px) {
              aspect-ratio: 4 / 3;
            }
            &:focus-visible {
              outline: none;
            }
          `}
        />
      </Stack>

      {/* <Stack
        css={css`
          width: 85%;
          margin-top: -50px;
        `}
      >
        <Stack direction="row" alignItems="center">
          <img
            src="marker_400view.png"
            width="30px"
            height="30px"
            alt="listentothecity"
          />
          <p
            css={css`
              margin: 0;
              font-size: 12px;
            `}
          >
            400VIEW
          </p>
        </Stack>
        <Stack direction="row" alignItems="center">
          <img
            src="marker_boma.png"
            width="30px"
            height="30px"
            alt="listentothecity"
          />
          <p>boma</p>
        </Stack>
        <img
          src="marker_listentothecity.png"
          width="30px"
          height="30px"
          alt="listentothecity"
        />
        <img
          src="marker_relay.png"
          width="30px"
          height="30px"
          alt="listentothecity"
        />
        <img
          src="marker_womantechlab.png"
          width="30px"
          height="30px"
          alt="listentothecity"
        />
        <img
          src="marker_euljiroetc.png"
          width="30px"
          height="30px"
          alt="listentothecity"
        />
        <img
          src="marker_ourstudio.png"
          width="30px"
          height="30px"
          alt="listentothecity"
        />
        <img
          src="marker_kimparkyu.png"
          width="30px"
          height="30px"
          alt="listentothecity"
        />
      </Stack> */}
    </Stack>
  );
};

export default Map;
