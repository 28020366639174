/** @jsxImportSource @emotion/react */
import { ReactNode, useEffect, useRef } from "react";
import Matter, { Engine, Render, Bodies, World, Bounds } from "matter-js";
import { css } from "@emotion/react";
import fourviewSrc from "../../assets/images/stacks/main_400view_border.svg";
import bomaSrc from "../../assets/images/stacks/main_boma_border.svg";
import designers2Src from "../../assets/images/stacks/main_designers2_border.svg";
import kimleekimSrc from "../../assets/images/stacks/main_kimleekim_border.svg";
import listenSrc from "../../assets/images/stacks/main_listen_border.svg";
import relaySrc from "../../assets/images/stacks/main_relay_border.svg";
import woman2Src from "../../assets/images/stacks/main_woman2_border.svg";
import yookimparkSrc from "../../assets/images/stacks/main_yookimpark_border.svg";
import stickerTitleSrc from "../../assets/images/stacks/sticker_title.svg";
import stickerTimeSrc from "../../assets/images/stacks/sticker_time.svg";
import stickerDateSrc from "../../assets/images/stacks/sticker_date.svg";
import { STUDIO_LOCATION_LIST } from "../screens";
import { useMediaQuery, useTheme } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
const navContainerStyle = css`
  @media (min-width: 1920px) {
    padding: 16px 48px;
  }
`;

const MatterFunction = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  let sceneRef = useRef<HTMLCanvasElement>(null);

  const world = document.querySelector(".canvas");
  const { Engine, Render, Runner, World, Bodies } = Matter;

  const theme = useTheme();
  const muiLgDown = useMediaQuery(theme.breakpoints.down("lg"));
  console.log(muiLgDown, "?");

  useEffect(() => {
    let engine = Engine.create() as any;

    const textures = [
      fourviewSrc,
      bomaSrc,
      designers2Src,
      kimleekimSrc,
      listenSrc,
      relaySrc,
      woman2Src,
      yookimparkSrc,
      stickerTitleSrc,
      stickerTimeSrc,
      stickerDateSrc,
    ];

    function init() {
      let width = sceneRef?.current?.clientWidth || 0;
      let height = sceneRef?.current?.clientHeight || 0;
      let vmin = Math.min(width ?? 10, height ?? 10);

      engine.events = {};
      World.clear(engine.world, true);
      Engine.clear(engine);
      engine = Engine.create();

      let render = Render.create({
        canvas: sceneRef.current ?? undefined,
        engine: engine,
        options: {
          wireframes: false,
          background: "transparent",
          width: width,
          height: height,
        },
      });

      World.add(engine.world, [
        //bottom wall
        Bodies.rectangle(width / 2, height + 50, width, 100, {
          isStatic: true,
          render: {
            fillStyle: "green",
          },
        }),
        //left wall
        Bodies.rectangle(-50, height / 2, 100, height * 2, {
          isStatic: true,
          render: {
            fillStyle: "green",
          },
        }),
        //right wall
        Bodies.rectangle(width + 50, height / 2, 100, height * 2, {
          isStatic: true,
          render: {
            fillStyle: "red",
          },
        }),
      ]);

      function create400view(i?: number) {
        // const SIZE = Math.floor(Math.random() * 76) + 30;
        const WIDTH = muiLgDown ? 190 : 380;
        const HEIGHT = muiLgDown ? 10 : 83;
        const rectangle = Bodies.rectangle(
          Math.round(Math.random() * width),
          10,
          WIDTH,
          HEIGHT,
          {
            density: 0.8,
            frictionAir: 0.01,
            restitution: 0,
            friction: 1,
            render: {
              strokeStyle: "red",
              lineWidth: 1,
              // visible: true,
              sprite: {
                texture: textures[0],
                xScale: muiLgDown ? 0.5 : 1,
                yScale: muiLgDown ? 0.5 : 1,
              },
            },
          }
        );

        return rectangle;
      }

      function createBoma(i?: number) {
        const WIDTH = muiLgDown ? 100 : 230;
        const HEIGHT = muiLgDown ? 40 : 80;
        const rectangle = Bodies.rectangle(
          Math.round(Math.random() * width),
          10,
          WIDTH,
          HEIGHT,
          {
            density: 0.8,
            frictionAir: 0.01,
            restitution: 0,
            friction: 1,
            render: {
              strokeStyle: "red",
              lineWidth: 1,
              // visible: true,
              sprite: {
                texture: textures[1],
                xScale: muiLgDown ? 0.7 : 1,
                yScale: muiLgDown ? 0.7 : 1,
              },
            },
          }
        );

        return rectangle;
      }
      function createDesigners(i?: number) {
        const WIDTH = muiLgDown ? 100 : 200;
        const HEIGHT = muiLgDown ? 80 : 160;
        const rectangle = Bodies.rectangle(
          Math.round(Math.random() * width),
          10,
          WIDTH,
          HEIGHT,
          {
            density: 0.8,
            frictionAir: 0.01,
            restitution: 0,
            friction: 1,
            render: {
              strokeStyle: "red",
              lineWidth: 1,
              // visible: true,
              sprite: {
                texture: textures[2],
                xScale: muiLgDown ? 0.7 : 1,
                yScale: muiLgDown ? 0.7 : 1,
              },
            },
          }
        );

        return rectangle;
      }

      function createKimLeeKim(i?: number) {
        const WIDTH = muiLgDown ? 135 : 270;
        const HEIGHT = muiLgDown ? 30 : 60;
        const rectangle = Bodies.rectangle(
          Math.round(Math.random() * width),
          10,
          WIDTH,
          HEIGHT,
          {
            density: 0.8,
            frictionAir: 0.01,
            restitution: 0,
            friction: 1,
            render: {
              strokeStyle: "red",
              lineWidth: 1,
              // visible: true,
              sprite: {
                texture: textures[3],
                xScale: muiLgDown ? 0.7 : 1,
                yScale: muiLgDown ? 0.7 : 1,
              },
            },
          }
        );

        return rectangle;
      }

      function createListen(i?: number) {
        const WIDTH = muiLgDown ? 135 : 270;
        const HEIGHT = muiLgDown ? 40 : 80;
        const rectangle = Bodies.rectangle(
          Math.round(Math.random() * width),
          10,
          WIDTH,
          HEIGHT,
          {
            density: 0.8,
            frictionAir: 0.01,
            restitution: 0,
            friction: 1,
            render: {
              strokeStyle: "red",
              lineWidth: 1,
              // visible: true,
              sprite: {
                texture: textures[4],
                xScale: muiLgDown ? 0.7 : 1,
                yScale: muiLgDown ? 0.7 : 1,
              },
            },
          }
        );

        return rectangle;
      }

      function createRelay(i?: number) {
        const WIDTH = muiLgDown ? 200 : 400;
        const HEIGHT = muiLgDown ? 60 : 120;
        const rectangle = Bodies.rectangle(
          Math.round(Math.random() * width),
          10,
          WIDTH,
          HEIGHT,
          {
            density: 0.8,
            frictionAir: 0.01,
            restitution: 0,
            friction: 1,
            render: {
              strokeStyle: "red",
              lineWidth: 1,
              // visible: true,
              sprite: {
                texture: textures[5],
                xScale: muiLgDown ? 0.5 : 1,
                yScale: muiLgDown ? 0.5 : 1,
              },
            },
          }
        );

        return rectangle;
      }

      function createWoman(i?: number) {
        const WIDTH = muiLgDown ? 150 : 300;
        const HEIGHT = muiLgDown ? 85 : 170;
        const rectangle = Bodies.rectangle(
          Math.round(Math.random() * width),
          10,
          WIDTH,
          HEIGHT,
          {
            density: 0.8,
            frictionAir: 0.01,
            restitution: 0,
            friction: 1,
            render: {
              strokeStyle: "red",
              lineWidth: 1,
              // visible: true,
              sprite: {
                texture: textures[6],
                xScale: muiLgDown ? 0.5 : 1,
                yScale: muiLgDown ? 0.5 : 1,
              },
            },
          }
        );

        return rectangle;
      }

      function createYookimpark(i?: number) {
        const WIDTH = muiLgDown ? 190 : 380;
        const HEIGHT = muiLgDown ? 25 : 50;
        const rectangle = Bodies.rectangle(
          Math.round(Math.random() * width),
          10,
          WIDTH,
          HEIGHT,
          {
            density: 0.8,
            frictionAir: 1,
            restitution: 0,
            friction: 1,
            render: {
              strokeStyle: "red",
              lineWidth: 1,
              // visible: true,
              sprite: {
                texture: textures[7],
                xScale: muiLgDown ? 0.5 : 1,
                yScale: muiLgDown ? 0.5 : 1,
              },
            },
          }
        );

        return rectangle;
      }

      function createSticker1(i?: number) {
        const WIDTH = muiLgDown ? 45 : 95;
        const ball = Bodies.circle(
          Math.round(Math.random() * width),
          -30,
          WIDTH,
          {
            angle: Math.PI * (Math.random() * 2 - 1),
            // axes: ,
            friction: 1,
            frictionAir: 0.01,
            restitution: 0,
            render: {
              sprite: {
                texture: textures[8],
                xScale: muiLgDown ? 0.3 : 0.6,
                yScale: muiLgDown ? 0.3 : 0.6,
              },
            },
          }
        );

        return ball;
      }
      function createSticker2(i?: number) {
        const WIDTH = muiLgDown ? 35 : 70;

        const ball = Bodies.circle(
          Math.round(Math.random() * width),
          -30,
          WIDTH,
          {
            angle: Math.PI * (Math.random() * 2 - 1),
            // axes: ,
            friction: 1,
            frictionAir: 0.01,
            restitution: 0,
            render: {
              sprite: {
                texture: textures[9],
                xScale: muiLgDown ? 0.3 : 0.5,
                yScale: muiLgDown ? 0.3 : 0.5,
              },
            },
          }
        );

        return ball;
      }
      function createSticker3(i?: number) {
        const WIDTH = muiLgDown ? 35 : 70;

        const ball = Bodies.circle(
          Math.round(Math.random() * width),
          -30,
          WIDTH,
          {
            angle: Math.PI * (Math.random() * 2 - 1),
            // axes: ,
            friction: 1,
            frictionAir: 0.01,
            restitution: 0,
            render: {
              sprite: {
                texture: textures[10],
                xScale: muiLgDown ? 0.4 : 0.7,
                yScale: muiLgDown ? 0.4 : 0.7,
              },
            },
          }
        );

        return ball;
      }

      Runner.run(engine);

      Render.run(render);

      const handleClick = (i?: number) => {
        const rect400View = create400view();
        const rectBoma = createBoma();
        const rectDesigners = createDesigners();
        const rectRelay = createRelay();
        const rectKimLeeKim = createKimLeeKim();
        const rectListen = createListen();
        const rectWoman = createWoman();
        const yookimpark = createYookimpark();

        const sticker1 = createSticker1();
        const sticker2 = createSticker2();
        const sticker3 = createSticker3();

        [
          rect400View,
          rectBoma,
          rectDesigners,
          rectRelay,
          rectKimLeeKim,
          rectListen,
          rectWoman,
          yookimpark,
          sticker1,
          sticker2,
          sticker3,
        ].map((v) => World.add(engine.world, [v]));

        // const rectangleInstance = STUDIO_LOCATION_LIST.map((v, i) =>
        //   createRectangle(i)
        // );
        // World.add(engine.world, rectangleInstance);

        // const rectangle2 = createRectangle();
        // World.add(engine.world, [rectangle2]);
      };
      // setInterval(handleClick, 2000);
      // STUDIO_LOCATION_LIST.map((v, i) => handleClick(i));
      handleClick();
    }

    init();

    window.addEventListener("resize", () => {
      init();
    });
    return window.removeEventListener("resize", () => {
      init();
    });
  }, []);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      `}
    >
      <div
        css={css`
          position: relative;
          margin-top: 10px;
          border: 1px solid #eee;
          /* box-shadow: inset 0 0 20px #f5f4f4; */
          /* @media (max-width: 720px) {
            padding: 0 20px;
          } */
        `}
        ref={wrapperRef}
      >
        <canvas
          ref={sceneRef}
          className="canvas"
          css={css`
            width: 100%;
            height: 70vh;
          `}
        ></canvas>
        <ReplayIcon
          css={css`
            position: absolute;
            top: 10px;
            right: 10px;

            color: #eee;
            &:hover {
              cursor: pointer;
              color: #000;
            }
          `}
          onClick={() => {
            window.location.reload();
          }}
        />
      </div>
    </div>
  );
};

export default MatterFunction;
